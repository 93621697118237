import React, { Component } from "react";
import ReactDOM from "react-dom";
import {Link, Redirect} from "react-router-dom";
import Header from "components/structure/Header";
import $ from "jquery";
import firebase,{firestore, storage} from "../firebase";
import FileUploader from "react-firebase-file-uploader";
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import Loading from "components/general/Loading";
import OpeningHoursLine from "components/general/OpeningHoursLine";


export default class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      shouldRedirect: false,
      companyName: "",
      profile: null,
      description: "",
      city: "",
      postcode: "",
      street: "",
      avatar: "",
      accountNumber: "",
      sortCode: "",
      isUploading: false,
      progress: 0,
      avatarURL: "",
      LocationLat: "",
      LocationLng: "",
      loadingPhotos: true,
      profilePhotos: null,
      photoCount: 0,
      openSunday: "0", closeSunday: "0", openPeakSunday: "0", closePeakSunday: "0",
      openMonday: "0", closeMonday: "0", openPeakMonday: "0", closePeakMonday: "0",
      openTuesday: "0", closeTuesday: "0", openPeakTuesday: "0", closePeakTuesday: "0",
      openWednesday: "0", closeWednesday: "0", openPeakWednesday: "0", closePeakWednesday: "0",
      openThursday: "0", closeThursday: "0", openPeakThursday: "0", closePeakThursday: "0",
      openFriday: "0", closeFriday: "0", openPeakFriday: "0", closePeakFriday: "0",
      openSaturday: "0", closeSaturday: "0", openPeakSaturday: "0", closePeakSaturday: "0",
      logoUploading: false,
      openValue: null,
      closedValue: null,
      openOPValue: null,
      closedOPValue: null,
    };


  } 

  async componentDidMount(){

    window.scrollTo(0,0);
    var user = await localStorage.getItem('user', null);
    this.setState({userId: user});
    if (user == null){
      this.setState({shouldRedirect: true});
    }else{
      firebase.firestore()
        .collection("providers")
        .doc(user)
        .get()
        .then(userDoc => {
              if (!userDoc.exists){
                this.setState({shouldRedirect: true});
              }else{
                this.setState({
                  profile: userDoc.data(), 
                  companyName: userDoc.data().companyName,
                  location: userDoc.data().location,
                  description: userDoc.data().description,
                  logo: userDoc.data().logo,
                  city: userDoc.data().city,
                  postcode: userDoc.data().postcode,
                  street: userDoc.data().street,
                  accountNumber: userDoc.data().accountNumber,
                  sortCode: userDoc.data().sortCode,
                  openSunday: userDoc.data().openingHours.openSunday, 
                  closeSunday: userDoc.data().openingHours.closeSunday, 
                  openPeakSunday: userDoc.data().openingHours.openPeakSunday, 
                  closePeakSunday: userDoc.data().openingHours.closePeakSunday,
                  openMonday: userDoc.data().openingHours.openMonday, 
                  closeMonday: userDoc.data().openingHours.closeMonday, 
                  openPeakMonday: userDoc.data().openingHours.openPeakMonday, 
                  closePeakMonday: userDoc.data().openingHours.closePeakMonday,
                  openTuesday: userDoc.data().openingHours.openTuesday, 
                  closeTuesday: userDoc.data().openingHours.closeTuesday, 
                  openPeakTuesday: userDoc.data().openingHours.openPeakTuesday, 
                  closePeakTuesday: userDoc.data().openingHours.closePeakTuesday,
                  openWednesday: userDoc.data().openingHours.openWednesday, 
                  closeWednesday: userDoc.data().openingHours.closeWednesday, 
                  openPeakWednesday: userDoc.data().openingHours.openPeakWednesday, 
                  closePeakWednesday: userDoc.data().openingHours.closePeakWednesday,
                  openThursday: userDoc.data().openingHours.openThursday, 
                  closeThursday: userDoc.data().openingHours.closeThursday, 
                  openPeakThursday: userDoc.data().openingHours.openPeakThursday, 
                  closePeakThursday: userDoc.data().openingHours.closePeakThursday,
                  openFriday: userDoc.data().openingHours.openFriday, 
                  closeFriday: userDoc.data().openingHours.closeFriday, 
                  openPeakFriday: userDoc.data().openingHours.openPeakFriday, 
                  closePeakFriday: userDoc.data().openingHours.closePeakFriday,
                  openSaturday: userDoc.data().openingHours.openSaturday, 
                  closeSaturday: userDoc.data().openingHours.closeSaturday, 
                  openPeakSaturday: userDoc.data().openingHours.openPeakSaturday, 
                  closePeakSaturday: userDoc.data().openingHours.closePeakSaturday,

                });

                if (this.state.logo == "" || this.state.logo == null){
                  this.setState({logo: "https://firebasestorage.googleapis.com/v0/b/timewash-4c5d1.appspot.com/o/timewash-placeholder-logo.png?alt=media&token=c094dd3c-7014-46f7-8a0f-9a0d97ff8cfa"});
                }
              }
        });


      firestore
        .collection('profilePhotos')
        .where("provider", "==", user)
        .onSnapshot(querySnapshot => {
          let profilePhotos=[]
          let photoCount = 0
          querySnapshot.forEach(doc=>{
            profilePhotos.push({...{id: doc.id}, ...doc.data()})
            photoCount++
          })
          this.setState({profilePhotos: profilePhotos})
          this.setState({loadingPhotos: false, photoCount: photoCount})
      })

    }





  }

  deleteProfilePhoto = (id) => {

    if (this.state.photoCount == 0){
      return alert('Your profile must have at least one photo');
    }

    firestore
      .collection('profilePhotos')
      .doc(id)
      .delete()
      .then(userDoc => {
          return true;
      }).catch(err => {
        return false;
      });

  }


  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    alert(error.message);
  };
  handleUploadSuccess = filename => {
    this.setState({progress: 100});
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => {

          firestore.collection("profilePhotos")
          .add({
            url: url,
            provider: this.state.userId,
            created: new Date().getTime(),
          })
          .then(() => {        
              
              this.setState({isUploading: false});
              

          })
          .catch(err => {
            this.setState({isUploading: false});
            alert(err.message);
          });


      });
  };

  handleUploadStartLogo = () => this.setState({ logoUploading: true, progress: 0 });
  handleProgressLogo = progress => this.setState({ progress });
  handleUploadErrorLogo = error => {
    this.setState({ logoUploading: false });
    alert(error.message);
  };
  handleUploadSuccessLogo = filename => {
    this.setState({progress: 100});
    this.setState({logoUploading: false});

    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => {

      firebase.firestore()
      .collection("providers")
      .doc(this.state.userId)
      .update({
        logo: url
      }).then(() =>{
        this.setState({logo: url});
        alert("Successfully uploaded your new logo!");

      });



      });



  };


  save = async () => {
    //basic
    //bank
    //opening hours
    //validation

    if (
      this.state.companyName == "" ||
      this.state.city == "" ||
      this.state.postcode == "" ||
      this.state.street == "" ||
      this.state.description == ""
    ){
      return alert('Please ensure you completed all fields!');
    }

    if (
      this.state.openSunday == "0" ||
      this.state.openMonday == "0" ||
      this.state.openTuesday == "0" ||
      this.state.openWednesday == "0" ||
      this.state.openThursday == "0" ||
      this.state.openFriday == "0" ||
      this.state.openSaturday == "0" ||

      this.state.closeSunday == "0" ||
      this.state.closeMonday == "0" ||
      this.state.closeTuesday == "0" ||
      this.state.closeWednesday == "0" ||
      this.state.closeThursday == "0" ||
      this.state.closeFriday == "0" ||
      this.state.closeSaturday == "0" ||

      this.state.openPeakSunday == "0" ||
      this.state.openPeakMonday == "0" ||
      this.state.openPeakTuesday == "0" ||
      this.state.openPeakWednesday == "0" ||
      this.state.openPeakThursday == "0" ||
      this.state.openPeakFriday == "0" ||
      this.state.openPeakSaturday == "0" ||

      this.state.closePeakSunday == "0" ||
      this.state.closePeakMonday == "0" ||
      this.state.closePeakTuesday == "0" ||
      this.state.closePeakWednesday == "0" ||
      this.state.closePeakThursday == "0" ||
      this.state.closePeakFriday == "0" ||
      this.state.closePeakSaturday == "0"
    ){
      return alert('Please fill out all the opening hours. Remember to select \'CLOSED\' if you are closed.');
    }



   await fetch("https://maps.google.com/maps/api/geocode/json?key=AIzaSyBHJGShh4gy10ZQCwomsjJqp9GLly282q4&address="+this.state.postcode+", UK")
      .then(res => res.json())
      .then(
        (result) => {

            this.setState({
              LocationLat: result.results[0].geometry.location.lat,
              LocationLng: result.results[0].geometry.location.lng,
            });   


        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          return alert('We could not process your postcode. Please check that it is correct and try again.');
          
        }
      )

    if (this.state.accountNumber == null){
      this.setState({accountNumber: "-"});
    }

    if (this.state.sortCode == null){
      this.setState({sortCode: "-"});
    }



    firebase.firestore()
    .collection("providers")
    .doc(this.state.userId)
    .update({
      openingHours: {
        openSunday: this.state.openSunday, 
        closeSunday: this.state.closeSunday, 
        openPeakSunday: this.state.openPeakSunday, 
        closePeakSunday: this.state.closePeakSunday,
        openMonday: this.state.openMonday, 
        closeMonday: this.state.closeMonday, 
        openPeakMonday: this.state.openPeakMonday, 
        closePeakMonday: this.state.closePeakMonday,
        openTuesday: this.state.openTuesday, 
        closeTuesday: this.state.closeTuesday, 
        openPeakTuesday: this.state.openPeakTuesday, 
        closePeakTuesday: this.state.closePeakTuesday,
        openWednesday: this.state.openWednesday, 
        closeWednesday: this.state.closeWednesday, 
        openPeakWednesday: this.state.openPeakWednesday, 
        closePeakWednesday: this.state.closePeakWednesday,
        openThursday: this.state.openThursday, 
        closeThursday: this.state.closeThursday, 
        openPeakThursday: this.state.openPeakThursday, 
        closePeakThursday: this.state.closePeakThursday,
        openFriday: this.state.openFriday, 
        closeFriday: this.state.closeFriday, 
        openPeakFriday: this.state.openPeakFriday, 
        closePeakFriday: this.state.closePeakFriday,
        openSaturday: this.state.openSaturday, 
        closeSaturday: this.state.closeSaturday, 
        openPeakSaturday: this.state.openPeakSaturday, 
        closePeakSaturday: this.state.closePeakSaturday     
      },
      companyName: this.state.companyName,
      city: this.state.city,
      postcode: this.state.postcode,
      street: this.state.street,
      description: this.state.description,
      accountNumber: this.state.accountNumber,
      sortCode: this.state.sortCode, 
      profileCompleted: true,
      LocationLat: this.state.LocationLat,
      LocationLng: this.state.LocationLng
    }).then(() =>{
      
      alert("Successfully saved your profile details! Your profile is now complete.");

    });


  }

  changeAllDaysOpen = (value) => {
    this.setState({
      allDaysOpen: value,
      openSunday: value,
      openMonday: value,
      openTuesday: value,
      openWednesday: value,
      openThursday: value,
      openFriday: value,
      openSaturday: value,
    });
  }

  changeAllDaysClosed = (value) => {
    this.setState({
      allDaysClosed: value,
      closeSunday: value,
      closeMonday: value,
      closeTuesday: value,
      closeWednesday: value,
      closeThursday: value,
      closeFriday: value,
      closeSaturday: value,
    });
  }


  changeAllDaysOPOpen = (value) => {
    this.setState({
      allDaysOPOpen: value,
      openPeakSunday: value,
      openPeakMonday: value,
      openPeakTuesday: value,
      openPeakWednesday: value,
      openPeakThursday: value,
      openPeakFriday: value,
      openPeakSaturday: value,
    });
  }


  changeAllDaysOPClosed = (value) => {
    this.setState({
      allDaysOPClosed: value,
      closePeakSunday: value,
      closePeakMonday: value,
      closePeakTuesday: value,
      closePeakWednesday: value,
      closePeakThursday: value,
      closePeakFriday: value,
      closePeakSaturday: value,
    });
  }




  render() {



    let cities = [
      "Adur and Worthing Borough",
      "Adur District",
      "Allerdale Borough",
      "Amber Valley Borough",
      "Arun District",
      "Ashfield District",
      "Ashford Borough",
      "Aylesbury Vale District",
      "Babergh District",
      "Barnsley Metropolitan Borough",
      "Barrow-in-Furness Borough",
      "Basildon Borough",
      "Basingstoke and Deane Borough",
      "Bassetlaw District",
      "Bath and North East Somerset",
      "Bedford Borough",
      "Birmingham City",
      "Blaby District",
      "Blackburn with Darwen Borough",
      "Blackpool Borough",
      "Blaenau Gwent County Borough",
      "Bolsover District",
      "Bolton Metropolitan Borough",
      "Borough of Broxbourne",
      "Borough of Poole",
      "Boston Borough",
      "Bournemouth Borough",
      "Bracknell Forest",
      "Bradford Metropolitan District",
      "Braintree District",
      "Breckland District",
      "Brentwood Borough",
      "Bridgend County Borough",
      "Brighton and Hove City",
      "Bristol City",
      "Broadland District",
      "Bromsgrove District",
      "Broxtowe Borough",
      "Buckinghamshire County",
      "Burnley Borough",
      "Bury Metropolitan Borough",
      "Caerphilly County Borough",
      "Calderdale Metropolitan Borough",
      "Cambridge City",
      "Cambridgeshire County",
      "Cannock Chase District",
      "Canterbury City",
      "Cardiff",
      "Carlisle City",
      "Carmarthenshire County",
      "Castle Point Borough",
      "Central Bedfordshire",
      "Ceredigion County",
      "Charnwood Borough",
      "Chelmsford City",
      "Cheltenham Borough",
      "Cherwell District",
      "Cheshire East (Unitary)",
      "Cheshire West and Chester",
      "Chesterfield Borough",
      "Chichester District",
      "Chiltern District",
      "Chorley",
      "Christchurch Borough",
      "City of Lincoln",
      "City of London",
      "City of York",
      "Colchester Borough",
      "Conwy County Borough",
      "Copeland Borough",
      "Corby Borough",
      "Cornwall (Unitary)",
      "Cotswold District",
      "Coventry City",
      "Craven District",
      "Crawley Borough",
      "Cumbria County",
      "Dacorum",
      "Darlington Borough",
      "Dartford Borough",
      "Daventry District",
      "Denbighshire County",
      "Derby City",
      "Derbyshire County",
      "Derbyshire Dales District",
      "Devon County",
      "Doncaster Metropolitan Borough",
      "Dorset County",
      "Dover District",
      "Dudley Metropolitan Borough",
      "Durham County",
      "East Cambridgeshire District",
      "East Devon District",
      "East Dorset District",
      "East Hampshire District",
      "East Hertfordshire District",
      "East Lindsey District",
      "East Northamptonshire",
      "East Riding of Yorkshire",
      "East Staffordshire Borough",
      "East Suffolk",
      "East Sussex County",
      "Eastbourne Borough",
      "Eastleigh Borough",
      "Eden District",
      "Elmbridge Borough",
      "Epping Forest District",
      "Epsom and Ewell Borough",
      "Erewash Borough",
      "Essex County",
      "Exeter City",
      "Fareham Borough",
      "Fenland District",
      "Flintshire County",
      "Forest Heath District",
      "Forest of Dean District",
      "Fylde Borough",
      "Gateshead Metropolitan Borough",
      "Gedling Borough",
      "Gloucester City",
      "Gloucestershire County",
      "Gosport Borough",
      "Gravesham Borough",
      "Great Yarmouth Borough",
      "Guildford Borough",
      "Gwynedd County",
      "Halton Borough",
      "Hambleton District",
      "Hampshire County",
      "Harborough District",
      "Harlow",
      "Harrogate Borough",
      "Hart District",
      "Hartlepool Borough",
      "Hastings Borough",
      "Havant Borough",
      "Herefordshire",
      "Hertfordshire County",
      "Hertsmere Borough",
      "High Peak Borough",
      "Hinckley and Bosworth Borough",
      "Horsham District",
      "Huntingdonshire District",
      "Hyndburn Borough",
      "Ipswich Borough",
      "Isle of Anglesey County",
      "Isle of Wight",
      "Isles of Scilly",
      "Kent County",
      "Kettering Borough",
      "King's Lynn and West Norfolk Borough",
      "Kingston-upon-Hull City",
      "Kirklees",
      "Knowsley Metropolitan Borough",
      "Lancashire County",
      "Lancaster City",
      "Leeds City",
      "Leicester City",
      "Leicestershire County",
      "Lewes District",
      "Lichfield District",
      "Lincolnshire County",
      "Liverpool City",
      "London Borough of Barking and Dagenham",
      "London Borough of Barnet",
      "London Borough of Bexley",
      "London Borough of Brent",
      "London Borough of Bromley",
      "London Borough of Camden",
      "London Borough of Croydon",
      "London Borough of Ealing",
      "London Borough of Enfield",
      "London Borough of Hackney",
      "London Borough of Hammersmith & Fulham",
      "London Borough of Haringey",
      "London Borough of Harrow",
      "London Borough of Havering",
      "London Borough of Hillingdon",
      "London Borough of Hounslow",
      "London Borough of Islington",
      "London Borough of Lambeth",
      "London Borough of Lewisham",
      "London Borough of Merton",
      "London Borough of Newham",
      "London Borough of Redbridge",
      "London Borough of Richmond upon Thames",
      "London Borough of Southwark",
      "London Borough of Sutton",
      "London Borough of Tower Hamlets",
      "London Borough of Waltham Forest",
      "London Borough of Wandsworth",
      "Luton Borough",
      "Maidstone Borough",
      "Maldon District",
      "Malvern Hills District",
      "Manchester City",
      "Mansfield District",
      "Medway",
      "Melton Borough",
      "Mendip District",
      "Merthyr Tydfil County Borough",
      "Mid Devon District",
      "Mid Suffolk District",
      "Mid Sussex District",
      "Middlesbrough Borough",
      "Milton Keynes",
      "Mole Valley District",
      "Monmouthshire County",
      "Neath Port Talbot County Borough",
      "New Forest District",
      "Newark and Sherwood District",
      "Newcastle-Under-Lyme District",
      "Newport City",
      "Newcastle-upon-Tyne City",
      "Norfolk County",
      "North Devon",
      "North Dorset District",
      "North East Derbyshire District",
      "North East Lincolnshire",
      "North Hertfordshire District",
      "North Kesteven District",
      "North Lincolnshire",
      "North Norfolk District",
      "North Somerset",
      "North Tyneside Metropolitan Borough",
      "North Warwickshire Borough",
      "North West Leicestershire District",
      "North Yorkshire County",
      "Northampton Borough",
      "Northamptonshire County",
      "Northumberland",
      "Norwich City",
      "Nottingham City",
      "Nottinghamshire County",
      "Nuneaton and Bedworth Borough",
      "Oadby and Wigston District",
      "Oldham Metropolitan Borough",
      "Oxford City",
      "Oxfordshire County",
      "Pembrokeshire County",
      "Pendle Borough",
      "Perth and Kinross",
      "Peterborough City",
      "Plymouth City",
      "Portsmouth City",
      "Powys County",
      "Preston City",
      "Purbeck District",
      "Reading Borough",
      "Redcar and Cleveland",
      "Redditch Borough",
      "Reigate & Banstead Borough",
      "Rhondda Cynon Taf County Borough",
      "Ribble Valley Borough",
      "Richmondshire District",
      "Rochdale Metropolitan Borough",
      "Rochford District",
      "Rossendale Borough",
      "Rother District",
      "Rotherham Metropolitan Borough",
      "Royal Borough of Greenwich",
      "Royal Borough of Kensington and Chelsea",
      "Royal Borough of Kingston upon Thames",
      "Royal Borough of Windsor and Maidenhead",
      "Rugby Borough",
      "Runnymede Borough",
      "Rushcliffe Borough",
      "Rushmoor Borough",
      "Rutland County",
      "Ryedale District",
      "Salford City",
      "Sandwell Metropolitan Borough",
      "Scarborough Borough",
      "Sedgemoor District",
      "Sefton Metropolitan Borough",
      "Selby District",
      "Sevenoaks District",
      "Sheffield City",
      "Shepway District",
      "Shropshire - Unitary",
      "Slough Borough",
      "Solihull Metropolitan Borough",
      "Somerset County",
      "South Buckinghamshire District",
      "South Cambridgeshire District",
      "South Derbyshire District",
      "South Gloucestershire",
      "South Hams District",
      "South Holland District",
      "South Kesteven District",
      "South Lakeland District",
      "South Norfolk District",
      "South Northamptonshire",
      "South Oxfordshire District",
      "South Ribble Borough",
      "South Somerset District",
      "South Staffordshire",
      "South Tyneside",
      "Southampton City",
      "Southend-on-Sea Borough",
      "Spelthorne Borough",
      "St Albans City and District",
      "St Edmundsbury Borough",
      "St Helens Metropolitan Borough",
      "Stafford Borough",
      "Staffordshire County",
      "Staffordshire Moorlands District",
      "Stevenage Borough",
      "Stockport Metropolitan Borough",
      "Stockton-on-Tees Borough",
      "Stoke-on-Trent City",
      "Strabane District",
      "Stratford-on-Avon District",
      "Stroud District",
      "Suffolk County",
      "Sunderland City",
      "Surrey County",
      "Surrey Heath Borough",
      "Swale Borough",
      "Swansea City and Borough",
      "Swindon Borough",
      "Tameside Metropolitan Borough",
      "Tamworth Borough",
      "Tandridge District",
      "Taunton Deane Borough",
      "Teignbridge District",
      "Telford & Wrekin",
      "Tendring District",
      "Test Valley Borough",
      "Tewkesbury Borough",
      "Thanet District",
      "Three Rivers District",
      "Thurrock",
      "Tonbridge and Malling Borough",
      "Torbay",
      "Torfaen County Borough",
      "Torridge District",
      "Trafford Metropolitan Borough",
      "Tunbridge Wells Borough",
      "Uttlesford District",
      "Vale of Glamorgan",
      "Vale of White Horse District",
      "Wakefield Metropolitan District",
      "Walsall Metropolitan Borough",
      "Warrington Borough",
      "Warwick District",
      "Warwickshire County",
      "Watford Borough",
      "Waverley Borough",
      "Wealden District",
      "Wellingborough Borough",
      "Welwyn Hatfield",
      "West Berkshire",
      "West Devon Borough",
      "West Dorset District",
      "West Lancashire Borough",
      "West Lindsey District",
      "West Oxfordshire District",
      "West Somerset District",
      "West Sussex County",
      "Westminster City",
      "Weymouth and Portland Borough",
      "Wigan Metropolitan Borough",
      "Wiltshire",
      "Winchester City",
      "Wirral",
      "Woking Borough",
      "Wokingham Borough",
      "Wolverhampton City",
      "Worcester City",
      "Worcestershire County",
      "Worthing Borough",
      "Wrexham County Borough",
      "Wychavon District",
      "Wycombe District",
      "Wyre",
      "Wyre Forest District",

    ];

    let timeslots = [
      'CLOSED',
      '06:00',
      '06:15',
      '06:30',
      '06:45',
      '07:00',
      '07:15',
      '07:30',
      '07:45',
      '08:00',
      '08:15',
      '08:30',
      '08:45',
      '09:00',
      '09:15',
      '09:30',
      '09:45',
      '10:00',
      '10:15',
      '10:30',
      '10:45',
      '11:00',
      '11:15',
      '11:30',
      '11:45',
      '12:00',
      '12:15',
      '12:30',
      '12:45',
      '13:00',
      '13:15',
      '13:30',
      '13:45',
      '14:00',
      '14:15',
      '14:30',
      '14:45',
      '15:00',
      '15:15',
      '15:30',
      '15:45',
      '16:00',
      '16:15',
      '16:30',
      '16:45',
      '17:00',
      '17:15',
      '17:30',
      '17:45',
      '18:00',
      '18:15',
      '18:30',
      '18:45',
      '19:00',
      '19:15',
      '19:30',
      '19:45',
      '20:00',
      '20:15',
      '20:30',
      '20:45',
      '21:00',
      '21:15',
      '21:30',
      '21:45',
      '22:00',
      '22:15',
      '22:30',
      '22:45',
      '23:00' 
    ];

    return (
        
        <>
        <Header selectedPage="profile"/>


        <section className="inner_section profile_inner_section">
          <div className="container">
            <div className="inner_page_title">
              <h2>Profile</h2>
            </div>
            <div className="inner_box">


              {!this.state.profile ? (
                <Loading/>
              ) : (

                <div className="profile_form_area">
                  <form>
                    <div className="profile_form_inputs_field">
                      <div className="row">
                        <div className="col-md-12" style={{'margin-bottom': '20px'}}>

                            {this.state.logo != "" &&
                              <div className="upload_logo_pic"><img src={this.state.logo} alt="logo" /></div>
                            }
                            <CustomUploadButton
                              accept="image/*"
                              storageRef={firebase.storage().ref('images')}
                              onUploadStart={this.handleUploadStartLogo}
                              onUploadError={this.handleUploadErrorLogo}
                              onUploadSuccess={this.handleUploadSuccessLogo}
                              onProgress={this.handleProgressLogo}
                            >
                              {this.state.logoUploading ? (
                                <div style={{"position": "relative", "bottom":"-35px"}}>
                                  <Loading/>
                                </div>
                              ) : (
                              <span className="btn upload_logo_btn cursorPointer">Upload Logo</span>
                              )}
                            </CustomUploadButton>

                        </div>
                        
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input type="text" className="form-control profile_form_inputs" placeholder="Company name"
                                onChange={e =>
                                  this.setState({companyName: e.target.value})
                                }
                                value={this.state.companyName}
                             />
                          </div>
                        </div>


                        <div className="col-md-6">
                          <select className="form-group form-control profile_form_inputs" 
                              onChange={event => this.setState({city: event.target.value})} 
                              value={this.state.city}>
                            <option selected value="">Your city</option>
                            {cities.map((city, index)=>{
                             return (
                              <option value={city}>{city}</option>
                              );
                            })}
                          </select>
                        </div>



                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input type="text" className="form-control profile_form_inputs" placeholder="Postcode"
                                onChange={e =>
                                  this.setState({postcode: e.target.value})
                                }
                                value={this.state.postcode}

                             />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input type="text" className="form-control profile_form_inputs" placeholder="Street name"
                                onChange={e =>
                                  this.setState({street: e.target.value})
                                }
                                value={this.state.street}

                             />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea className="form-control profile_form_textarea" placeholder="Profile description" rows={5} 
                                onChange={e =>
                                  this.setState({description: e.target.value})
                                }
                            >{this.state.description}</textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="profile_add_time_area">
                     
                      <div className="row">
                         
                        <div className="col-md-12">
                          <h3>Bank account to receive weekly payouts</h3>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input type="text" className="form-control profile_form_inputs" placeholder="Bank account number"
                                    onChange={e =>
                                      this.setState({accountNumber: e.target.value})
                                    }
                                    value={this.state.accountNumber}
                                 />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input type="text" className="form-control profile_form_inputs" placeholder="Bank sort code"
                                    onChange={e =>
                                      this.setState({sortCode: e.target.value})
                                    }
                                    value={this.state.sortCode}

                                 />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="profile_add_photo_area">
                     
                          


                      <div className="row">

                        <div className="col-12">
                          <br/>
                          <h3>Photo Gallery (optional) (click an image to delete)</h3>


                          {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                          {this.state.filename && <img src={this.state.filename} />}
                        </div>
                        <div className="col-4">
                          <CustomUploadButton
                            accept="image/*"
                            storageRef={firebase.storage().ref('images')}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            className="upload_drag_drop"
                          >
                                <div className="upload_drag_drop_txt">
                                  {this.state.isUploading ? (
                                    <img src="images/loading.gif" alt="img" />
                                  ): (
                                    <img src="images/upload_photo_icon.png" alt="img" />
                                  )}
                                  
                                  <h4>Add Photo</h4>
                                </div>
                          </CustomUploadButton>
                        </div>

                          
                        {this.state.loadingPhotos &&
                          <Loading/>
                        }


                       
                       {!this.state.loadingPhotos && this.state.profilePhotos.map((photo, index)=>{
                         return (
                          <div className="col-4 outerProfilePhoto">
                            <div className="profile_add_photo_item outerProfilePhotoInner cursorPointer">
                              <img src={photo.url} alt="img" onClick={() => { if (window.confirm('Are you sure you want to delete this photo?')) this.deleteProfilePhoto(photo.id) } } />
                            </div>
                          </div>
                          );
                       })}




                      </div>
                    </div>
                    <div className="profile_add_time_area">
                      <h3>Opening Times</h3>
                      <div className="row">
                        
                        <div className="col-md-12">
                         

                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Every day hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.changeAllDaysOpen(event.target.value)} 
                                          value={this.state.allDaysOpen}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.changeAllDaysClosed(event.target.value)} 
                                          value={this.state.allDaysClosed}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>{this.props.day} Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">

                                      <select className="form-control" 
                                          onChange={event => this.changeAllDaysOPOpen(event.target.value)} 
                                          value={this.state.allDaysOPOpen}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>

                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.changeAllDaysOPClosed(event.target.value)} 
                                          value={this.state.allDaysOPClosed}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                     
                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Sunday hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openSunday: event.target.value})} 
                                          value={this.state.openSunday}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closeSunday: event.target.value})} 
                                          value={this.state.closeSunday}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Sunday Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openPeakSunday: event.target.value})} 
                                          value={this.state.openPeakSunday}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closePeakSunday: event.target.value})} 
                                          value={this.state.closePeakSunday}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Monday hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openMonday: event.target.value})} 
                                          value={this.state.openMonday}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closeMonday: event.target.value})} 
                                          value={this.state.closeMonday}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Monday Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openPeakMonday: event.target.value})} 
                                          value={this.state.openPeakMonday}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closePeakMonday: event.target.value})} 
                                          value={this.state.closePeakMonday}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Tuesday hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openTuesday: event.target.value})} 
                                          value={this.state.openTuesday}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closeTuesday: event.target.value})} 
                                          value={this.state.closeTuesday}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Tuesday Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openPeakTuesday: event.target.value})} 
                                          value={this.state.openPeakTuesday}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closePeakTuesday: event.target.value})} 
                                          value={this.state.closePeakTuesday}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Wednesday hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openWednesday: event.target.value})} 
                                          value={this.state.openWednesday}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closeWednesday: event.target.value})} 
                                          value={this.state.closeWednesday}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Wednesday Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openPeakWednesday: event.target.value})} 
                                          value={this.state.openPeakWednesday}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closePeakWednesday: event.target.value})} 
                                          value={this.state.closePeakWednesday}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Thursday hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openThursday: event.target.value})} 
                                          value={this.state.openThursday}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closeThursday: event.target.value})} 
                                          value={this.state.closeThursday}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Thursday Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openPeakThursday: event.target.value})} 
                                          value={this.state.openPeakThursday}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closePeakThursday: event.target.value})} 
                                          value={this.state.closePeakThursday}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Friday hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openFriday: event.target.value})} 
                                          value={this.state.openFriday}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closeFriday: event.target.value})} 
                                          value={this.state.closeFriday}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Friday Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openPeakFriday: event.target.value})} 
                                          value={this.state.openPeakFriday}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closePeakFriday: event.target.value})} 
                                          value={this.state.closePeakFriday}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                           <div className="row" style={{"margin-top": "30px"}}>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">

                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Saturday hours</h5>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openSaturday: event.target.value})} 
                                          value={this.state.openSaturday}>
                                        <option selected value="0">Opening Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closeSaturday: event.target.value})} 
                                          value={this.state.closeSaturday}>
                                        <option selected value="0">Closing Time</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile_add_time_select_area">
                                  <div className="off_pack_title off_pack_title_desktop">
                                    <h5>Saturday Off Peak hours</h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({openPeakSaturday: event.target.value})} 
                                          value={this.state.openPeakSaturday}>
                                        <option selected value="0">Start</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <select className="form-control" 
                                          onChange={event => this.setState({closePeakSaturday: event.target.value})} 
                                          value={this.state.closePeakSaturday}>
                                        <option selected value="0">End</option>
                                        {1==1 && timeslots.map((slot, index)=>{
                                         return (
                                          <option value={slot}>{slot}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>





                        </div>
                      </div>
                    </div>
                    <div className="profile_form_submit">
                      <button type="button" className="hoverOpacity" onClick={this.save}>Save</button>
                    </div>
                  </form>
                </div>
              )}

            </div>
          </div>
        </section>

        {this.state.shouldRedirect &&
          <Redirect to="/" push />
        }


        </>



      
    );
  }
}

