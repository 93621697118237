import React, { Component } from "react";
import ReactDOM from "react-dom";
import firebase,{firestore, storage} from "../firebase";
import {Link} from "react-router-dom";
import Header from "components/structure/Header";
import $ from "jquery";
import SubmitQuoteModal from "components/modals/SubmitQuoteModal";
import New from "components/modals/New";
import Loading from "components/general/Loading";
import {getQuote} from "functions/functions";
import GoogleMapReact from 'google-map-react';

var moment = require('moment');

export default class RequestDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      id: null,
      quote: false,
      expired: false,
      requestUserId: null
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }


  openModal (){
    this.setState({showModal: true});
  }

  closeModal = () => {
    this.setState({showModal: false});
  }


  componentDidMount(){
    window.scrollTo(0,0)

    const requestId = this.props.match.params.id;
    this.setState({id: requestId});



    firestore
      .collection("requests")
      .doc(requestId)
      .get()
      .then(doc => {
        if (doc.exists){
          this.setState({
            request: doc.data()
          });

          //get user of request

          firestore
            .collection("users")
            .doc(doc.data().user)
            .get()
            .then(userDoc => {
              if (userDoc.exists){
                this.setState({
                  requestUser: userDoc.data(),
                  requestUserId: doc.data().user
                });
              }
            });


            var now = moment(new Date()); //now
            var end = moment(this.state.request.created)
            var duration = moment.duration(now.diff(end));
            var hours = duration.asHours();

            if (hours >= 24){
              this.setState({expired: true});
            }else{
              this.setState({expired: false});
            }   




        }
      });



      var user = localStorage.getItem('user', null);
      if (user == null){
        return alert('You must be logged in');
      }
      this.setState({userId: user});

      //check if quoted

      firestore
      .collection("quotes")
      .doc(requestId+"-"+user)
      .get()
      .then(doc => {
        if (doc.exists){
          this.setState({quote: doc.data()});
        }else{
          this.setState({quote: false()});
        }
      })
      .catch(err => {
          this.setState({quote: false});
      });  


  }

  refresh = () => {
    

    this.setState({request: null});

    const requestId = this.props.match.params.id;
    this.setState({id: requestId});

    firestore
      .collection("requests")
      .doc(requestId)
      .get()
      .then(doc => {
        if (doc.exists){
          this.setState({
            request: doc.data()
          });

          //get user of request

          firestore
            .collection("users")
            .doc(doc.data().user)
            .get()
            .then(userDoc => {
              if (userDoc.exists){
                this.setState({
                  requestUser: userDoc.data()
                });
              }
            });


        }
      });


      //check if quoted

      firestore
      .collection("quotes")
      .doc(requestId+"-"+this.state.userId)
      .get()
      .then(doc => {
        if (doc.exists){
          this.setState({quote: doc.data()});
        }else{
          this.setState({quote: false()});
        }
      })
      .catch(err => {
          this.setState({quote: false});
      });  


  }


  withdrawQuote = () => {

    firestore
      .collection('quotes')
      .doc(this.state.id+"-"+this.state.userId)
      .delete()
      .then(() => {
          alert('Your quote has been withdrawn!');
          //window.location.reload()
          this.refresh();
      }).catch(err => {
        alert('An error occurred when withdrawing your quote - please try again later.');
      });

  }


  render() {

 
    return (
        
        <>
        <Header selectedPage="requests"/>

        <SubmitQuoteModal 
          request={this.state.request}
          requestId={this.state.id}
          requestUser={this.state.requestUser} 
          requestUserId={this.state.requestUserId}
          show={this.state.showModal} 
          onClose={this.closeModal}
          refresh={this.refresh}/>

        <section className="inner_section">
          <div className="container">
            <Link to="/requests/all">
              <div className="inner_page_back">
                <a href="#">
                  <img src="/images/pree-arrow.png" alt="icon" />All requests
                </a>
              </div>
            </Link>
            <div className="inner_page_title">
              <h2>Requests details</h2>
            </div>
            <div className="booking_details_box">
              {this.state.request && this.state.requestUser ? (
              <>


              {this.state.quote && 
                <div className="booking_details_upper">
                  <div className="row">
                      <div className="col-md-4">
                        <div className="request_details_down_left">
                          <h6>Quote price</h6>
                          <h4>£{parseFloat(this.state.quote.price).toFixed(2)}</h4>
                        </div>
                        <div className="request_details_down_left" style={{marginTop: 10}}>
                          <h6>Slots</h6>
                          <h4>{moment(this.state.quote.slot1).format('DD MMM, hh:mmA')}</h4>
                          <h4>{moment(this.state.quote.slot2).format('DD MMM, hh:mmA')}</h4>
                          <h4>{moment(this.state.quote.slot3).format('DD MMM, hh:mmA')}</h4>
                        </div>

                      </div>
                      <div className="col-md-4">
                        <div className="request_details_down_right">
                          <h6>Comment</h6>
                          <p>
                            "{this.state.quote.comment}"
                          </p>
                        </div>
                      </div>

                    <div className="col-md-4">
                      <div className="booking_details_upper_btn">
                        <button 
                          className="btn booking_cancel_btn"
                          onClick={() => { if (window.confirm('Are you sure you want to withdraw this quote? Any credits spent on this quote will not be refunded.')) this.withdrawQuote() } }>Withdraw</button>
                      </div>
                    </div>
                  </div>
                </div>
              }


              <div className="customer_details_area">
                <div className="customer_details_box">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="details_title">
                        <h4><img className="details_title_icon" src="/images/user-green.png" alt="img" /> Customer Details</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="customer_details_item">
                        <div className="customer_details_item_type">
                          {this.state.requestUser.name}
                        </div>
                        <div className="customer_details_item_location">
                          <img className="map_marker" src="/images/map-marker.png" alt="img" /> {this.state.request.city}
                        </div>
                       
                        <div className="car_wash_info">
                          Request placed
                          <strong>{moment(this.state.request.created).format('DD MMM YYYY, HH:mm A')}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="details_map_area" style={{"width": "100%", "height": "240px", "padding-top": "0px", "border-radius":"5px"}}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: 'AIzaSyB6d-rF2pnx7bGdqLqgTNJUgdqWaBPg5gA' }}
                          defaultCenter={{lat: this.state.request.LocationLat, lng: this.state.request.LocationLng}}
                          defaultZoom={15}
                        >
                        </GoogleMapReact>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="car_details_area">
                <div className="row">
                  <div className="col-md-12">
                    <div className="details_title">
                      <h4><img className="details_title_icon" src="/images/cr.png" alt="img" /> Car Details</h4>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="car_details_content">

                        <h5>{this.state.request.car.make + " " + this.state.request.car.model } {this.state.request.isGift == "Yes, this is a gift" && <img src="/images/giftIcon.png" alt="img" className="giftIcon" />}</h5>
                        <p>
                          {this.state.request.car.reg}
                          <span className="request_user_dot">•</span>
                          {this.state.request.car.color}
                          <span className="request_user_dot">•</span>
                          {this.state.request.car.doors} Doors
                          <span className="request_user_dot">•</span>
                          {this.state.request.carWashType ? this.state.request.carWashType : "Standard"}
                        </p>
                    </div>
                  </div>

                  {this.state.request.images.map(image => 


                    <div className="col-md-3">
                      <div className="car_details_item">
                        <img src={image.split("----")[1]} alt={image.split("----")[0]} />
                        <div className="car_details_item_info">
                          <span>{image.split("----")[0]}</span>
                        </div>
                      </div>
                    </div>


                  )}  


                </div>
              </div>

              
              {1==1 ? (
                <div className="profile_form_submit" style={{paddingBottom: '25px'}}>
                  <button onClick={this.openModal} type="button" className="hoverOpacity strongShadowButton">Submit a quote</button>
                </div>
              ) : (

                <span className="badge badge-warning" style={{'margin-bottom': '15px', 'margin': '0 auto 30px auto', 'display': 'block', 'width': '200px'}}>Expired or booked (or quoted)</span>

              )}



              </>

              ) : (

              <Loading/>

              )}
            </div>
          </div>
        </section>


        </>



      
    );
  }
}

