import * as firebase from 'firebase';
import '@firebase/firestore'
// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBoU-pH_7U4hGAQXbs3WHzozCsqmXqpo_M",
    authDomain: "timewash-4c5d1.firebaseapp.com",
    databaseURL: "https://timewash-4c5d1.firebaseio.com",
    projectId: "timewash-4c5d1",
    storageBucket: "timewash-4c5d1.appspot.com",
    messagingSenderId: "787248456348",
    appId: "1:787248456348:web:af330cee56392114"
};
firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export default firebase