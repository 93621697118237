import React, { PureComponent } from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import {Modal} from 'react-bootstrap';

import { firestore } from "../../firebase";
import DateTimePicker from 'material-ui-datetimepicker';
import DatePickerDialog from 'material-ui/DatePicker/DatePickerDialog'
import TimePickerDialog from 'material-ui/TimePicker/TimePickerDialog';
import DateFnsUtils from "@date-io/date-fns"; // import
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import New from './New'

var NumberFormat = require('react-number-format');
var moment = require('moment');


export default class SubmitQuoteModal extends PureComponent {
 

  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      slot1: null,
      slot2: null,
      slot3: null,
      quotePrice: null,
      quoteComment: null,
      loading: false,
      dateTime: null,
      requestId: this.props.requestId

    };

    this.changeSlot1 = this.changeSlot1.bind(this);
    this.changeSlot2 = this.changeSlot2.bind(this);
    this.changeSlot3 = this.changeSlot3.bind(this);

  }

  show = () => {
      this.external.instance.show();
  }
  
  setExternal = (comp) => {
      this.external = comp;
  }

  changeSlot1(date) {
    this.setState({
      slot1: date
    });
  }


  changeSlot2(date) {
    this.setState({
      slot2: date
    });
  }

  changeSlot3(date) {
    this.setState({
      slot3: date
    });
  }

  changeQuotePrice = (event) => {
    this.setState({quotePrice: event.target.value.replace('£', '')})
                                  

  }

  submitQuote = async () => {

    var user = await localStorage.getItem('user', null);
    if (user == null){
      return alert('You must be logged in to submit a quote');
    }

    if (this.state.quotePrice == null || this.state.quoteComment == null || this.state.slot1 == null || this.state.slot2 == null || this.state.slot3 == null){
      return alert('Please fill in all fields to submit a quote');
    }

    

    var quotePrice = parseFloat(this.state.quotePrice);
    var quoteComment = this.state.quoteComment;
    var slot1 = parseInt(moment(this.state.slot1).format('x'));
    var slot2 = parseInt(moment(this.state.slot2).format('x'));
    var slot3 = parseInt(moment(this.state.slot3).format('x'));


    if (
          !moment(slot1).isAfter(new Date())
        || !moment(slot2).isAfter(new Date())
        || !moment(slot3).isAfter(new Date())){
      return alert('Please make sure all slots are in the future');
    }

    this.setState({loading: true});


    firestore.collection("providers")
      .doc(user)
      .get()
      .then(userDoc => {
            if (!userDoc.exists){
              this.setState({loading: false});

              return alert('Sorry, an error occured.');

            }else{

              if (userDoc.data().profileVerified == false || userDoc.data().profileCompleted == false){
                return alert("To submit quotes, your profile must be complete and verified. If you have completed your profile, it will take upto 5 days for us to verify it. Thank you!");
              }

              /*var creditsAfterQuote = userDoc.data().credits - 10;
              if (userDoc.data().credits < 10){
                return alert('You must have at least 10 credits to submit this quote.');
              }*/

           

                  firestore.collection("quotes")
                     .doc(this.props.requestId+"-"+user)
                      .set({
                      request: this.props.requestId,
                      provider: user,
                      price: quotePrice,
                      comment: quoteComment,
                      slot1: slot1,
                      slot2: slot2,
                      slot3: slot3,
                    })
                    .then(() => {        
                        

                        fetch('https://tw-app-api.herokuapp.com/all/send-quote-notification', {
                          method: 'POST',
                          headers: { 'Accept': 'application/json','Content-Type': 'application/json'},
                          body: JSON.stringify({
                            customer: this.props.requestUserId,
                          })

                        }).then(response => response.json()) 
                        .then(res => {

                            console.log(res.response.toString())
                          alert('Your quote has been sent successfully!');



                          this.setState({loading: false});

                          //window.location.reload();
                          this.props.refresh();
                          this.props.onClose();
                          
                        }).catch(error => {
                          console.log(error);
                          alert('Your quote has been sent successfully!');



                          this.setState({loading: false});

                          //window.location.reload();
                          this.props.refresh();
                          this.props.onClose();
                        });





                    })
                    .catch(err => {
                      this.stopProcessing();
                      alert(err.message);
                      console.log("Something went wrong with your booking, DB error.");
                    });




            }
      });








    
  }


  render() {

    console.log('Render add tab modal');

    return (
        <Modal 
        size="md"
        show={this.props.show} onHide={this.props.onClose} className="site_modal submitModalMainContainer" id="submit_modal" role="dialog" aria-labelledby="exampleModalCenterTitle" tabindex={1000} aria-hidden="true">
          

          {this.props.request && this.props.requestUser &&
          <div className="modal-dialog" role="document" style={{height: '100%'}}>

            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">Submit a Quote</h5>
                <button type="button" className="close" onClick={this.props.onClose} aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">



                <div className="modal_top_box">
                  <div className="request_details">
                    <h4>{this.props.requestUser.name} - {this.props.request.car.make} {this.props.request.car.model}</h4>
                    <p>
                      {this.props.request.car.reg}
                      <span className="request_user_dot">•</span>
                      {this.props.request.location}
                    </p>
                    <ul>
                      {this.props.request.images.map(image => 

                        <li>
                          
                          <img src={image.split("----")[1]} />
                        </li>

                      )}   
                    </ul>
                  </div>
                </div>
                <div className="submit_modal_form">

                  <form>
                    <div className="profile_form_inputs_field">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <NumberFormat 
                                  prefix={'£'}
                                  className="form-control profile_form_inputs"
                                  placeholder="Add quote price (£)"   
                                  decimalScale={2}  
                                  fixedDecimalScale={true}    
                                  allowNegative={false}           
                                  allowEmptyFormatting={false}   
                                  isNumericString={true}           
                                  onChange={event => this.changeQuotePrice(event)}
                                  value={this.state.quotePrice}  />

                              </div>

                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea 
                                  className="form-control profile_form_textarea" 
                                  placeholder={"Send a message to "+this.props.requestUser.name} 
                                  rows={3} 
                                  
                                  onChange={e =>
                                    this.setState({quoteComment: e.target.value})
                                  }>{this.state.quoteComment}</textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label style={{width: '100%'}}>
                                Select available slot 1
                                <input 
                                  className="form-control profile_form_inputs"
                                  placeholder="Select available slot 1"   
                                  type={"datetime-local"}
                                  onChange={e =>
                                    this.setState({slot1: e.target.value})
                                  }
                                  value={this.state.slot1}                                  
                                 />
                                </label>

                              </div>

                              <div className="form-group">
                                <label style={{width: '100%'}}>
                                Select available slot 2
                                <input 
                                  className="form-control profile_form_inputs"
                                  placeholder="Select available slot 2"   
                                  type={"datetime-local"}
                                  onChange={e =>
                                    this.setState({slot2: e.target.value})
                                  }
                                  value={this.state.slot2}                                  
                                 />
                                </label>

                              </div>

                              <div className="form-group">
                                <label style={{width: '100%'}}>
                                Select available slot 3
                                <input 
                                  className="form-control profile_form_inputs"
                                  placeholder="Select available slot 3"   
                                  type={"datetime-local"}
                                  onChange={e =>
                                    this.setState({slot3: e.target.value})
                                  }
                                  value={this.state.slot3} 

                                 />
                                </label>

                              </div>




                            </div>
                            
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="submit_modal_btn">
                            <button type="button" className="hoverOpacity strongShadowButton" onClick={this.submitQuote}>
                              {!this.state.loading ? 
                                
                                <span>Submit Quote</span>

                                :

                                <img src="/images/loading.gif" className={"loadingGifCircle"}/>
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          }
        </Modal>
    );
  }
}

