import React, { Component } from "react";
import ReactDOM from "react-dom";
import { firestore, auth } from "../firebase";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import Header from "components/structure/Header";
import $ from "jquery";
import Loading from "components/general/Loading";

export default class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userDoc: null,
      shouldRedirect: false
    };

  }

 

  componentDidMount() {
    window.scrollTo(0,0)

    var user = localStorage.getItem('user', null);
    if (user == null){
      return alert('Please login again');
    }


    this.setState({user: user});


    firestore
      .collection("providers")
      .doc(user)
      .get()
      .then(userDoc => {
            if (userDoc.exists){
              this.setState({userDoc: userDoc.data()});
            }else{
              return alert('Please login again.');
            }
      });




  }


  logout = () => {

    if (window.confirm('Are you sure you want to logout of your account?')){

      auth
      .signOut()
        .then(() => {
          localStorage.clear();
          this.setState({shouldRedirect: true});

        })
        .catch(err => {
          alert("Error logging out - please try again later.");
        });
      }

  }




  render() {
    return (
        
        <>
        <Header selectedPage="settings"/>

        <section className="inner_section transaction_inner_section">
          <div className="container">
            <div className="inner_page_title">
              <h2>Referrals</h2>
            </div>

            {this.state.shouldRedirect &&
              <Redirect to="/" push />
            }

            <div className="transactions_top_box">
                {this.state.userDoc &&
                <div className="row">
                  <div className="col-3">
                    <div className="transactions_top_item">
                      <h5>Total Referred<br/> Users</h5>
                      <h3>{this.state.userDoc.referralCount}</h3>
                    </div>
                  </div>
                 
                  <div className="col-5">
                    <div className="transactions_top_item_3">
                      <h5>Referral<br/> Credits</h5>
                      <h3><span>{this.state.userDoc.referralCredits}</span></h3>
                    </div>
                  </div>
                  <div className="col-4 hideOnMobile">
                    <div className="transactions_top_btns noPointerEvents">
                      <button style={{"width":"100%", "max-width": "100%"}}>{this.state.user}</button>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="transactions_top_content" style={{'margin-top': '20px'}}>
                      <p>Refer new customers and providers and earn credits, send them your code: <strong>{this.state.user}</strong></p>
                    </div>
                  </div>
                </div>
                }
              
            </div>

          

            {this.state.userDoc &&
            <>
            <div className="inner_page_title" style={{'margin-top': '55px'}}>
              <h2>Account</h2>
            </div>
            <div className="transactions_top_box">
              
                <div className="row">
                  <div className="col-9">
                    <div className="transactions_top_item">
                      <h5>{this.state.userDoc.fullName}</h5>
                      <h3>{this.state.userDoc.email}</h3>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="transactions_top_btns hoverOpacity">
                      <button onClick={this.logout} style={{'margin-top': '20px'}}>Logout</button>
                    </div>
                  </div>
                 
                </div>
              
            </div>
            </>
            }




          </div>


        </section>

       

        </>



      
    );
  }
}

