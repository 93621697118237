import React, { Component } from "react";
import ReactDOM from "react-dom";
import { firestore } from "../firebase";
import {Link} from "react-router-dom";
import Header from "components/structure/Header";
import AllBookingsItem from "components/listItems/AllBookingsItem";
import $ from "jquery";
import Loading from "components/general/Loading";

export default class ViewAllBookings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingBookings: true,
      Bookings: null,
      show: true,
      bookingCount: 0,
      showUpcoming: true
    };

  }

  
  componentDidMount() {

    window.scrollTo(0,0)

    var user = localStorage.getItem('user', null);
    if (user == null){
      return alert('Please login again');
    }

    this.setState({loadingBookings: true})
    firestore
    .collection('bookings')
    .where('provider', '==', user)
    .onSnapshot(querySnapshot => {
      let Bookings=[]
      var bookingCount = 0;
      querySnapshot.forEach(doc=>{
        Bookings.push({...{id: doc.id}, ...doc.data()})

        bookingCount++;
      })
      console.log(Bookings)
      this.setState({Bookings: Bookings, bookingCount: bookingCount})
      this.setState({loadingBookings: false})
    })


  }

  render() {
    return (
        
        <>
        <Header selectedPage="bookings"/>

        <section className="requests_area">
          <div className="container">
            <div className="inner_page_title">
              <h2>Bookings</h2>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="inner_tabs">
                  {/* Nav tabs */}
                  <ul className="nav nav-tabs">
                    <Link to={'/bookings/upcoming'} onClick={()=>this.setState({showUpcoming: true})} className="nav-item">
                    
                        <a className="nav-link active" data-toggle="tab" href="#">Upcoming</a>
                    </Link>
                    <li className="nav-item" onClick={()=>this.setState({showUpcoming: false})}>
                      <a className="nav-link" data-toggle="tab" href="#">Past</a>
                    </li>

                  </ul>
                  {/* Tab panes */}
                  <div className="tab-content">
                    <div className="tab-pane active container" id="all-requests">

                      {this.state.loadingBookings &&
                        <Loading/>
                      }


                      {!this.state.loadingBookings && this.state.bookingCount == 0 &&
                        <h5 style={{'margin-top': '20px'}}>No upcoming bookings</h5>
                      }



                     
                     {!this.state.loadingBookings && this.state.Bookings.map((booking, index)=>{
                       return (
                         <AllBookingsItem
                          key={index}
                          booking={booking}
                          showUpcoming={this.state.showUpcoming}
                          />
                        );
                     })}
                     

                    </div>
                    <div className="tab-pane container" id="my-responses">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        </>



      
    );
  }
}

