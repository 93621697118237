import React, { Component } from "react";
import ReactDOM from "react-dom";
import firebase,{firestore, storage} from "../firebase";
import {Link} from "react-router-dom";
import Header from "components/structure/Header";
import $ from "jquery";
import SubmitQuoteModal from "components/modals/SubmitQuoteModal";
import New from "components/modals/New";
import Loading from "components/general/Loading";
import {getQuote} from "functions/functions";
import GoogleMapReact from 'google-map-react';

var moment = require('moment');

export default class BookingDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      id: null,
      cancelled: false,
      processing: false
    };
  }



  componentDidMount(){
    window.scrollTo(0,0)

    const bookingId = this.props.match.params.id;
    this.setState({id: bookingId});



    firestore
      .collection("bookings")
      .doc(bookingId)
      .get()
      .then(doc => {
        if (doc.exists){
          this.setState({
            booking: doc.data()
          });

          //get user of request

          firestore
            .collection("users")
            .doc(doc.data().user)
            .get()
            .then(userDoc => {
              if (userDoc.exists){
                this.setState({
                  requestUser: userDoc.data()
                });
              }
            });


        }
      });



      var user = localStorage.getItem('user', null);
      if (user == null){
        return alert('You must be logged in');
      }
      this.setState({userId: user});


  }

  refresh = () => {
    

    this.setState({request: null});

    const requestId = this.props.match.params.id;
    this.setState({id: requestId});

    firestore
      .collection("requests")
      .doc(requestId)
      .get()
      .then(doc => {
        if (doc.exists){
          this.setState({
            request: doc.data()
          });

          //get user of request

          firestore
            .collection("users")
            .doc(doc.data().user)
            .get()
            .then(userDoc => {
              if (userDoc.exists){
                this.setState({
                  requestUser: userDoc.data()
                });
              }
            });


        }
      });


      //check if quoted

      firestore
      .collection("quotes")
      .doc(requestId+"-"+this.state.userId)
      .get()
      .then(doc => {
        if (doc.exists){
          this.setState({quote: doc.data()});
        }else{
          this.setState({quote: false()});
        }
      })
      .catch(err => {
          this.setState({quote: false});
      });  


  }

  cancelBooking = () => {

    var now = moment(this.state.booking.time); //now
    var end = moment(new Date())
    var duration = moment.duration(now.diff(end));
    var hours = duration.asHours();

    if (hours <= 24){

      return alert('You can only cancel a booking more than 24 hours before.');
    
    }

    if (this.state.cancelled == true || this.state.booking.cancelled == true){
      return alert("This booking has already been cancelled");
    }



    this.setState({processing: true});
    fetch('https://tw-app-api.herokuapp.com/all/cancel', {
      method: 'POST',
      headers: { 'Accept': 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        booking: this.state.booking,
        bookingId: this.state.id,
        provider: this.state.booking.provider,
        user: this.state.booking.user,
      })

    }).then(response => response.json()) 
    .then(res => {

        this.setState({cancelled: true});
        alert(res.response.toString())
        this.setState({processing: false});


      
    }).catch(error => {
      alert('An error occured');
      this.setState({processing: false});

    });



  }



  render() {



    return (
        
        <>
        <Header selectedPage="bookings"/>

        <section className="inner_section">
          <div className="container">
            <Link to="/bookings/upcoming">
              <div className="inner_page_back">
                <a href="#">
                  <img src="/images/pree-arrow.png" alt="icon" />All bookings
                </a>
              </div>
            </Link>
            <div className="inner_page_title">
              <h2>Booking details</h2>
            </div>
            <div className="booking_details_box">
              {this.state.booking && this.state.requestUser ? (
              <>


              
                <div className="booking_details_upper">
                  <div className="row">
                      <div className="col-md-4">
                        <div className="request_details_down_left">
                          <h6>Booking Price</h6>
                          <h4>£{parseFloat(this.state.booking.price).toFixed(2)}</h4>
                        </div>
                        <div className="request_details_down_left" style={{marginTop: 10}}>
                          <h6>Booking date and time</h6>
                          <h4>{moment(this.state.booking.time).format('DD MMM, hh:mmA')}</h4>
                        </div>

                      </div>
                     

                    <div className="col-md-8">
                      <div className="booking_details_upper_btn">
                      {this.state.cancelled == false && this.state.booking.cancelled != true ? (
                        <button 
                          className="btn booking_cancel_btn"
                          disabled={this.state.processing}
                          onClick={() => { if (window.confirm('Are you sure you want to cancel this booking? Your customer can still review you.')) this.cancelBooking() } }>
                          {this.state.processing ? (
                            <span>Processing...</span>
                          ) : (
                            <span>Cancel booking</span>
                          )}
                        </button>
                      ) : (
                        <span className="badge badge-danger" style={{'margin-bottom': '15px'}}>Cancelled</span>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              


              <div className="customer_details_area">
                <div className="customer_details_box">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="details_title">
                        <h4><img className="details_title_icon" src="/images/user-green.png" alt="img" /> Customer Details</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="customer_details_item">
                        <div className="customer_details_item_type">
                          {this.state.requestUser.name}
                        </div>
                        <div className="customer_details_item_location">
                          <img className="map_marker" src="/images/map-marker.png" alt="img" /> {this.state.booking.originalRequest.city}
                        </div>
                       
                        <div className="car_wash_info">
                          Request placed
                          <strong>{moment(this.state.booking.created).format('DD MMM YYYY, HH:mm A')}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="details_map_area" style={{"width": "100%", "height": "240px", "padding-top": "0px", "border-radius":"5px"}}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: 'AIzaSyB6d-rF2pnx7bGdqLqgTNJUgdqWaBPg5gA' }}
                          defaultCenter={{lat: this.state.booking.originalRequest.LocationLat, lng: this.state.booking.originalRequest.LocationLng}}
                          defaultZoom={15}
                        >
                        </GoogleMapReact>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="car_details_area">
                <div className="row">
                  <div className="col-md-12">
                    <div className="details_title">
                      <h4><img className="details_title_icon" src="/images/cr.png" alt="img" /> Car Details</h4>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="car_details_content">


                      <h5>{this.state.booking.originalRequest.car.make + " " + this.state.booking.originalRequest.car.model } {this.state.booking.originalRequest.isGift == "Yes, this is a gift" && <img src="/images/giftIcon.png" alt="img" className="giftIcon" />}</h5>
                      <p>
                        {this.state.booking.originalRequest.car.reg}
                        <span className="request_user_dot">•</span>
                        {this.state.booking.originalRequest.car.color}
                        <span className="request_user_dot">•</span>
                        {this.state.booking.originalRequest.car.doors} Doors
                        <span className="request_user_dot">•</span>
                        {this.state.booking.originalRequest.carWashType ? this.state.booking.originalRequest.carWashType : "Standard"}
                      </p>
                    </div>
                  </div>

                  {this.state.booking.images.map(image => 


                    <div className="col-md-3">
                      <div className="car_details_item">
                        <img src={image.split("----")[1]} alt={image.split("----")[0]} />
                        <div className="car_details_item_info">
                          <span>{image.split("----")[0]}</span>
                        </div>
                      </div>
                    </div>


                  )}  


                </div>
              </div>

              </>

              ) : (

              <Loading/>

              )}
            </div>
          </div>
        </section>


        </>



      
    );
  }
}

