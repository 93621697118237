import React, { Component } from "react";
import ReactDOM from "react-dom";
import {CardElement, injectStripe, StripeProvider, Elements} from 'react-stripe-elements';


class _CardForm extends Component {


  state = {
    processing: false,
    final: false
  };

  purchase = () => {
    var self = this;
    this.setState({processing: true});
    this.props.stripe.createToken().then(payload => {

      if (payload.error != null){

        this.setState({processing: false});
        alert(payload.error.message);
      }else if (payload.token != null){
          fetch('https://tw-app-api.herokuapp.com/provider/purchase-credits', {
            method: 'POST',
            headers: { 'Accept': 'application/json','Content-Type': 'application/json'},
            body: JSON.stringify({
              token: payload.token.id,
              provider: self.props.provider,
              credits: self.props.credits,
              price: self.props.price
            })

          }).then(response => response.json()) 
          .then(res => {

              alert(res.response.toString())
              this.setState({final: true});

            
          }).catch(error => {
            alert('An error occured');
            this.setState({processing: false});

          });

      }

    })

  }


  render() {
    return (
      <div className={this.state.final == true && "shadeBox"}>
       
        <CardElement hidePostalCode={true} />
        <button disabled={this.state.processing} className="hoverOpacity removeButtonStyles" onClick={this.purchase}>
          {!this.state.processing ? (
            <span>Purchase credits (£{this.props.price})</span>
          ) : (
            <span>Processing</span>
          )}
        </button>
      </div>
    )
  }
}
const CardForm = injectStripe(_CardForm)


class CreditCheckout extends Component {



  render (){

    return (

          <Elements>
            <div className="checkout">
              <CardForm price={this.props.price} credits={this.props.credits} provider={this.props.provider}/>
            </div>
          </Elements>

    );

  }


}

export default CreditCheckout;