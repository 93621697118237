import React, { Component } from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import { firestore } from "../../firebase";
import Img from 'react-image';
import PreloadImage from 'react-preload-image'

var moment = require('moment');




export default class AllRequestsItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      quote: null
    };

    var user = localStorage.getItem('user', null);
    if (user == null){
      return alert("You must be logged in!");
    }

    firestore
    .collection("quotes")
    .doc(this.props.request.id+"-"+user)
    .get()
    .then(quote => {
      if (quote.exists){
        this.setState({quote: quote.data()});
      }
    })
    .catch(err => {
        console.log(err.message);
    }); 


  }
  /*

        firestore
        .collection("quotes")
        .doc(doc.id+"-"+user)
        .get()
        .then(quote => {
          if (quote.exists){
            requests.push({...{id: doc.id}, ...doc.data(), ...{quote: quote.data()}})
          }else{
            requests.push({...{id: doc.id}, ...doc.data(), ...{quote: null}})
          }
        })
        .catch(err => {
            requests.push({...{id: doc.id}, ...doc.data(), ...{quote: null}})
        });  

  */


  render() {

  	

    var now = moment(new Date()); //now
    var end = moment(this.props.request.created)
    var duration = moment.duration(now.diff(end));
    var hours = duration.asHours();

    if (hours >= 24){
      var expired = true;
    }else{
      var expired = false;
    }    


  	var requestDetailsLink = "/request/"+this.props.request.id;

    return (
      <div className={this.props.show == false && this.state.quote == null ? "request_box hideBox" : "request_box"}>
        <div className="request_user">
          {this.props.request.hasBooked != true && expired == false ? (
            <span></span>
          ) : (
            <>
            <span className="badge badge-warning" style={{'margin-bottom': '15px'}}>Expired or booked</span>
            <br/>
            </>
          )}
          <span className="request_user_pic"><img src="/images/user.png" alt="img" /></span>
          <span className="request_user_name">New Request</span>
          <span className="request_user_type">in {this.props.request.city}</span>
          <span className="request_user_dot">•</span>
          <span className="request_user_time">{moment(this.props.request.created).fromNow()}</span>
        </div>
        <div className="request_details_area">
          <div className="row">
            <div className="col-7">
              <div className="request_details">


                <h4>{this.props.request.car.make} {this.props.request.car.model} {this.props.request.isGift == "Yes, this is a gift" && <img src="/images/giftIcon.png" alt="img" className="giftIcon" />}</h4>
                <p>
                  {this.props.request.car.reg}
                  <span className="request_user_dot">•</span>
                  {this.props.request.car.color}
                  <span className="request_user_dot">•</span>
                  {this.props.request.carWashType ? this.props.request.carWashType : "Standard"}
                </p>

                <ul>

                  {this.props.request.images.map(image => 

                    <li>
                      <img src={image.split("----")[1]} alt={image.split("----")[0]}/>
                     
                    </li>

                  )}   

                </ul>
              </div>
            </div>
            {this.props.request.hasBooked != true && expired == false &&
            <div className="col-5 align-bottom">
              <div className="request_details_buttons">
              	<Link to={requestDetailsLink}>
                	<button type="button" className="request_brn hoverOpacity">View Request</button>
                </Link>
                {/*<button type="button" className="withdraw_brn hoverOpacity">Hide</button>*/}
              </div>
            </div>
            }
          </div>
        </div>
        {this.state.quote != null && this.props.request.id == this.state.quote.request &&
        <div className="request_details_down">
          <div className="row">
            <div className="col-7">
              <div className="request_details_down_left">
                <h6>Quote price</h6>
                <h4>£{parseFloat(this.state.quote.price).toFixed(2)}</h4>
              </div>
            </div>
            <div className="col-5">
              <div className="request_details_down_right">
                <h6>Comment</h6>
                <p>
                  “{this.state.quote.comment}”
                </p>
              </div>
            </div>
          </div>
        </div>
        }


      </div>
    );
  }
}

