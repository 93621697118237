import React, { PureComponent } from "react";
import $ from "jquery";
import {Modal} from 'react-bootstrap';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import firebase,{firestore} from "../../firebase";
import DateTimePicker from 'material-ui-datetimepicker';
import DatePickerDialog from 'material-ui/DatePicker/DatePickerDialog'
import TimePickerDialog from 'material-ui/TimePicker/TimePickerDialog';
import DateFnsUtils from "@date-io/date-fns"; // import
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import New from './New'

var NumberFormat = require('react-number-format');
var moment = require('moment');


export default class SignupModal extends PureComponent {
 

  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
      companyName: '',
      shouldRedirect: false,
      referralCode: ''
    };



  }

  showLogin = () => {
    this.props.onHide();
    this.props.showLogin();
  }

  componentDidMount(){
  }

  signup = async () => {

    if (this.state.fullName === "") return alert("Please enter your name");
    if (this.state.companyName === "") return alert("Please enter your company name");
    if (this.state.email === "") return alert("Please enter your email");
    if (this.state.password === "") return alert("Please enter a password");
    if (this.state.password.trim().length < 6){
      return alert("Your password must be at least 6 characters");
    }
    if (this.state.confirmPassword === ""){
      return alert("Please confirm your password");
    }
    if (this.state.password !== this.state.confirmPassword){
      return alert("Password and confirm password do not match");
    }

    var referralCode = this.state.referralCode.trim();

    this.setState({processing: true})
    firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(userDoc => {          
        firestore
          .collection("providers")
          .doc(userDoc.user.uid)
          .set({
            fullName: this.state.fullName,
            companyName: this.state.companyName,
            email: this.state.email,
            profileCompleted: false,
            profileVerified: false,
            created: new Date().getTime(),
            logo: "",
            street: "",
            postcode: "",
            city: "",
            stars: 0,
            reviewsCount: 0,
            referralCount: 0,
            referralCredits: 0,
            credits: 100,
            openingHours: {
              openSunday: "0",
              closeSunday: "0",
              openPeakSunday: "0",
              closePeakSunday: "0",
              openMonday: "0",
              closeMonday: "0",
              openPeakMonday: "0",
              closePeakMonday: "0",
              openTuesday: "0",
              closeTuesday: "0",
              openPeakTuesday: "0",
              closePeakTuesday: "0",
              openWednesday: "0",
              closeWednesday: "0",
              openPeakWednesday: "0",
              closePeakWednesday: "0",
              openThursday: "0",
              closeThursday: "0",
              openPeakThursday: "0",
              closePeakThursday: "0",
              openFriday: "0",
              closeFriday: "0",
              openPeakFriday: "0", 
              closePeakFriday: "0",
              openSaturday: "0",
              closeSaturday: "0",
              openPeakSaturday: "0",
              closePeakSaturday: "0",
            }
          })
          .then(async (user) => {


              if (referralCode != ""){


                  fetch('https://tw-app-api.herokuapp.com/provider/process-referral', {
                    method: 'POST',
                    headers: { 'Accept': 'application/json','Content-Type': 'application/json'},
                    body: JSON.stringify({
                      referer: referralCode,
                    })

                  }).then(response => response.json()) 
                  .then(async (res) => {

                      this.setState({processing: false})
                      await localStorage.setItem('user', userDoc.user.uid)              
                      this.setState({shouldRedirect: true});

                    
                  }).catch(async (error) => {
                    alert('Your referral code is invalid');
                    this.setState({processing: false});
                    
                    await localStorage.setItem('user', userDoc.user.uid)   
                    this.setState({shouldRedirect: true});

                  });

              }else{
                    this.setState({processing: false});
                    await localStorage.setItem('user', userDoc.user.uid)              
                    this.setState({shouldRedirect: true});
              }




          })
          .catch(err => {
            this.setState({processing: false})
            alert(err.message);
          });



      })
      .catch(err => {
        this.setState({processing: false})
        alert(err.message);
      });


  }




  render() {

    return (
        <Modal 
        size="sm"
        show={this.props.show}
        backdrop={true} onHide={this.props.onHide} id="signupModal" className="modal fade" role="dialog">
          <div className="modal-dialog modal-sm">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <p className="heading-modal">Signup as a service provider on TimeWash</p>
                <div>
                  <div className="form-group">
                    <input 
                      type="text" 
                      placeholder="Full Name" 
                      className="form-control"
                      onChange={e =>
                        this.setState({fullName: e.target.value})
                      }
                      value={this.state.fullName}
                       />
                    
                  </div>
                  <div className="form-group">
                    <input 
                      type="text" 
                      placeholder="Company Name" 
                      className="form-control"
                      onChange={e =>
                        this.setState({companyName: e.target.value})
                      }
                      value={this.state.companyName}
                       />
                    
                  </div>
                  <div className="form-group">
                    <input type="email" placeholder="Email" className="form-control"
                      onChange={e =>
                        this.setState({email: e.target.value})
                      }
                      value={this.state.email}/>
                  </div>
                  <div className="form-group">
                    <input type="password" placeholder="Password" className="form-control"
                      onChange={e =>
                        this.setState({password: e.target.value})
                      }
                      value={this.state.password}/>
                  </div>
                  <div className="form-group">
                    <input type="password" placeholder="Confirm Password" className="form-control"
                      onChange={e =>
                        this.setState({confirmPassword: e.target.value})
                      }
                      value={this.state.confirmPassword}/>
                  </div>

                  <div className="form-group">
                    <input 
                      type="text" 
                      placeholder="Referral code (optional)" 
                      className="form-control"
                      onChange={e =>
                        this.setState({referralCode: e.target.value})
                      }
                      value={this.state.referralCode}
                       />
                    
                  </div>

                  <div className="form-group accept-terms text-grey">
                    By signing up, you agree to our privacy policy and terms of service.
                  </div>

                  <div className="form-group">
                    <button disabled={this.state.processing} className="btn btn-success col-12" onClick={this.signup}>
                    
                      {!this.state.processing ? 
                        
                        <span>Sign Up</span>

                        :

                        <img src="/images/loading.gif" style={{ width: '20px',height: '20px', position: 'relative', top: '-2px'}}/>
                      }
                    </button>
                  </div>
                </div>
                <p className="text-grey">Have an Account? <a href="#" onClick={this.showLogin}>Login Now</a></p>
              </div>
            </div>
          </div>
          {this.state.shouldRedirect &&
            <Redirect to="/requests/all" push />
          }

          

        </Modal>
    );
  }
}

