import React, { Component } from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import Img from 'react-image';
import PreloadImage from 'react-preload-image'

var moment = require('moment');




export default class AllBookingsItem extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {

  	

  	var bookingDetailsLink = "/booking/"+this.props.booking.id;

    if (this.props.showUpcoming == true){
      if (((this.props.booking.time) > new Date().getTime())){
        var show = true;
      }else{
        var show = false;
      }
    }else{
      if (((this.props.booking.time) < new Date().getTime())){
        var show = true;
      }else{
        var show = false;
      }
    }

    return (
    <div>
      <div className={show == true ? "booking_box" : "booking_box hideBox"}>
        <div className="request_details_area">
          <div className="row">
            <div className="col-9">
              <div className="request_user">

                {this.props.booking.cancelled != true ? (
                  <span></span>
                ) : (
                  <>
                  <span className="badge badge-danger" style={{'margin-bottom': '15px'}}>Cancelled</span>
                  <br/>
                  </>
                )}

                <span className="request_user_pic"><img src="/images/user.png" alt="img" /></span>
                <span className="request_user_name">New booking</span>
                <span className="request_user_type">from your quote</span>
                <span className="request_user_dot">•</span>
                <span className="request_user_time">{moment(this.props.booking.created).fromNow()}</span>
              </div>
              <div className="request_details">
                <h4>{this.props.booking.car.make} {this.props.booking.car.model} {this.props.booking.originalRequest.isGift == "Yes, this is a gift" && <img src="/images/giftIcon.png" alt="img" className="giftIcon" />} {this.props.booking.charge == 'CASH' && '(CASH)'}</h4>
                   <p>
                  {this.props.booking.car.reg}
                  <span className="request_user_dot">•</span>
                  {this.props.booking.car.color}
                  <span className="request_user_dot">•</span>
                  {this.props.booking.originalRequest.carWashType ? this.props.booking.originalRequest.carWashType : "Standard"}
                </p>
                <ul>


                  {this.props.booking.images.map(image => 

                    <li>
                      <img src={image.split("----")[1]} alt={image.split("----")[0]}/>
                    </li>

                  )}   

                </ul>
              </div>
            </div>
            <div className="col-3">
              <div className="bookings_details_right">
                <h6>Wash Date</h6>
                <h5>{moment(this.props.booking.time).format('DD MMM YYYY, HH:mm A')}</h5>
                <h6>Payment paid <i className="fas fa-check bookingPaymentTick" /></h6>
                  <h5>£{parseFloat(this.props.booking.price).toFixed(2)}</h5>
                  <Link to={bookingDetailsLink}>
                    <button type="button" className="hoverOpacity booking_brn">View Booking</button>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



        );
      }
}

