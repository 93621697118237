import React, { Component } from "react";
import ReactDOM from "react-dom";
import { firestore } from "../firebase";
import {Link} from "react-router-dom";
import Header from "components/structure/Header";
import CreditCheckout from "components/general/CreditCheckout";
import $ from "jquery";
import {CardElement, injectStripe, StripeProvider, Elements} from 'react-stripe-elements';
import Loading from "components/general/Loading";
var moment = require('moment');


export default class Credits extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingCard1: false,
      card1loaded: false,
      provider: null,
      userDoc: null,
      loadingCredits: false,
      credits: null
    };

  }

  componentDidMount(){

    window.scrollTo(0,0)


    var user = localStorage.getItem('user', null);
    if (user == null){
      return alert('Please login again');
    }


    this.setState({provider: user});


    firestore
      .collection("providers")
      .doc(user)
      .get()
      .then(userDoc => {
            if (userDoc.exists){
              this.setState({userDoc: userDoc.data()});
            }else{
              return alert('Please login again.');
            }
      });


      this.setState({loadingCredits: true})
      firestore
      .collection('credits')
      .where('provider', '==', user)
      .onSnapshot(querySnapshot => {
        let credits=[]
        querySnapshot.forEach(doc=>{
          credits.push({...{id: doc.id}, ...doc.data()})
        })
        console.log(credits)
        this.setState({credits: credits})
        this.setState({loadingCredits: false})
      })




  }







  render() {
    return (
        
        <>
        <Header selectedPage=""/>

        <section className="inner_section transaction_inner_section">
          <div className="container">
            <div className="inner_page_title">
              <h2>Credits</h2>
            </div>
            <div className="transactions_top_box">
              <div className="row">
                

                <div className="col-3">
                  <div className="transactions_top_item_3">
                    <h5>My Credits</h5>
                    <h3>{this.state.userDoc && this.state.userDoc.credits != null ? this.state.userDoc.credits : 0}</h3>
                  </div>
                </div>
                <div className="col-3">
                  <div className="transactions_top_item">
                    <h5>Credits per quote</h5>
                    <h3>10</h3>
                  </div>
                </div>


              </div>
            </div>
            <div className="row">

              <div className="col-md-4 col-sm-12">
                
                <div className="service_plan">
                  <h3>100 credits - £3</h3>
                  <ul>
                    <li>Submit 10 quotes</li>
                    <li>Start using credits instantly</li>
                    <li>Pay as you go</li>
                    <li>£3</li>
                  </ul>


                   <StripeProvider apiKey="pk_live_5FL1v8VF4SBPzWM4438oINii00hia2Njwk">
                    <div>
                      <CreditCheckout
                        price="3"
                        credits="100"
                        provider={this.state.provider}/>
                    </div>
                  </StripeProvider>


                </div>

              </div>



              <div className="col-md-4 col-sm-12">
                
                <div className="service_plan">
                  <h3>500 credits - £12</h3>
                  <ul>
                    <li>Submit 50 quotes</li>
                    <li>Start using credits instantly</li>
                    <li>Pay as you go</li>
                    <li>£12</li>
                  </ul>


                  
                   <StripeProvider apiKey="pk_live_5FL1v8VF4SBPzWM4438oINii00hia2Njwk">
                    <div>
                      <CreditCheckout
                        price="12"
                        credits="500"
                        provider={this.state.provider}/>
                    </div>
                  </StripeProvider>

                </div>

              </div>



              <div className="col-md-4 col-sm-12">
                
                <div className="service_plan">
                  <h3>1000 credits - £19</h3>
                  <ul>
                    <li>Submit 100 quotes</li>
                    <li>Start using credits instantly</li>
                    <li>Pay as you go</li>
                    <li>£19 - best value</li>
                  </ul>




                   <StripeProvider apiKey="pk_live_5FL1v8VF4SBPzWM4438oINii00hia2Njwk">
                    <div>
                      <CreditCheckout
                        price="19"
                        credits="1000"
                        provider={this.state.provider}/>
                    </div>
                  </StripeProvider>

                </div>

              </div>





            </div>
            <div className="transactions_table_box">
              <table border={0} cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th>Credits</th>
                    <th>Price</th>
                    <th>Receipt</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
  

                    {this.state.loadingCredits &&
                      <Loading/>
                    }


                   
                   {!this.state.loadingCredits && this.state.credits != null && this.state.credits.map((credit, index)=>{
                     return (

                      <tr>
                        <td><strong>{credit.credits}</strong></td>
                        <td>£{credit.price}</td>
                        <td><a href={credit.receipt} target={"_blank"}>{credit.receipt.substr(0, 50)}</a></td>
                        <td>{moment(credit.time).format('DD MMM, hh:mmA')}</td>
                      </tr>

                      );
                   })}


                      <tr>
                        <td><strong>100</strong></td>
                        <td>£-</td>
                        <td><a href="#">Introductory 100 credits</a></td>
                        <td>On signup</td>
                      </tr>


                </tbody>
              </table>
            </div>
          </div>
        </section>



        </>



      
    );
  }
}

