import React, { Component } from "react";
import ReactDOM from "react-dom";
import { firestore } from "../firebase";
import {Link} from "react-router-dom";
import Header from "components/structure/Header";
import $ from "jquery";
import ResponsesItem from "components/listItems/ResponsesItem";
import ReactModal from 'react-modal';

import Loading from "components/general/Loading";

export default class ViewMyResponses extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
        
        <>
        <Header selectedPage="requests"/>

        <section className="requests_area">
          <div className="container">
            <div className="inner_page_title">
              <h2>Requests</h2>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="inner_tabs">
                  {/* Nav tabs */}
                  <ul className="nav nav-tabs">
                   <Link to={'/requests/all'}>
                      <li className="nav-item">
                        <a className="nav-link" href="#">All Requests</a>
                      </li>
                    </Link>
                    <Link to={'/requests/responses'}>
                      <li className="nav-item">
                        <a className="nav-link active" href="#">My responses</a>
                      </li>
                    </Link>
                  </ul>
                  {/* Tab panes */}
                  <div className="tab-content">
                    <div className="tab-pane active container" id="my-responses">

                      <ResponsesItem/>
                      <ResponsesItem/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>



      
    );
  }
}

