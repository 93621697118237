import React from "react";
import ReactDOM from "react-dom";
import ViewAllRequests from "views/ViewAllRequests";
import ViewMyResponses from "views/ViewMyResponses";
import RequestDetails from "views/RequestDetails";
import BookingDetails from "views/BookingDetails";
import ViewAllBookings from "views/ViewAllBookings";
import MarketingHome from "views/MarketingHome";
import Profile from "views/Profile";
import Transactions from "views/Transactions";
import Credits from "views/Credits";
import Settings from "views/Settings";


import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/styles/font-awsome-v5.7.2.css"; 
import "assets/styles/bootstrap.min.css"; 
import "assets/styles/style.css"; 
import "assets/styles/responsive.css"; 


ReactDOM.render(
<BrowserRouter>
  <Route path="/" component={MarketingHome} exact/>

  <Route
    path="/requests"
    render={({ match: { url } }) => (
      <>
        <Route path={`${url}/all`} component={ViewAllRequests}/>
        <Route path={`${url}/responses`} component={ViewMyResponses} exact/>   
      </>
    )}
  />

  <Route
    path="/bookings"
    render={({ match: { url } }) => (
      <>
        <Route path={`${url}/upcoming`} component={ViewAllBookings}/>
        <Route path={`${url}/past`} component={ViewAllBookings} exact/>   
      </>
    )}
  />

  <Route path="/request/:id" component={RequestDetails} exact />
  <Route path="/booking/:id" component={BookingDetails} exact />
  <Route path="/profile" component={Profile} exact/>
  <Route path="/transactions" component={Transactions} exact/>
  <Route path="/credits" component={Credits} exact/>
  <Route path="/settings" component={Settings} exact/>

</BrowserRouter>,
  document.getElementById("root")


);




