import React, { Component } from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import Img from 'react-image';
import PreloadImage from 'react-preload-image'

var moment = require('moment');




export default class ResponsesItem extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {

  	

  	//var requestDetailsLink = "/request/"+this.props.request.id;

    return (
      <div className="request_box">
        <div className="request_user">
          <span className="request_user_pic"><img src="/images/user.png" alt="img" /></span>
          <span className="request_user_name">Robert Downey</span>
          <span className="request_user_type">requested car wash</span>
          <span className="request_user_dot">•</span>
          <span className="request_user_time">2 MINS AGO</span>
        </div>
        <div className="request_details_area">
          <div className="row">
            <div className="col-7">
              <div className="request_details">
                <h4>Vauxhall Corsa</h4>
                <p>
                  CV62 BNB
                  <span className="request_user_dot">•</span>
                  Newcastle NE1
                </p>
                <ul>
                  <li>
                    <img src="/images/Rectangle.png" alt="img" />
                  </li>
                  <li>
                    <img src="/images/Rectangle.png" alt="img" />
                  </li>
                  <li>
                    <img src="/images/Rectangle.png" alt="img" />
                  </li>
                  <li>
                    <img src="/images/Rectangle.png" alt="img" />
                  </li>
                  <li>
                    <img src="/images/Rectangle.png" alt="img" />
                  </li>
                  <li>
                    <img src="/images/Rectangle.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-5 align-bottom">
              <div className="request_details_buttons">
                <button type="button" className="request_brn hoverOpacity" onclick="location.href='request-details.html'">View Request</button>
                
              </div>
            </div>
          </div>
        </div>
        <div className="request_details_down">
          <div className="row">
            <div className="col-7">
              <div className="request_details_down_left">
                <h6>Quote price</h6>
                <h4>£8.70</h4>
              </div>
            </div>
            <div className="col-5">
              <div className="request_details_down_right">
                <h6>Comment</h6>
                <p>
                  “Thanks for the request….. we’d be happy to do your car wash - we’re just 5 minutes away from you.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

