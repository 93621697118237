import React, { Component } from "react";
import {Link} from "react-router-dom";
import $ from "jquery";


export default class Loading extends Component {

  render() {


    return (
      <img src="/images/loading-fast.gif" className="loadingGIF"/>
    );
  }
}

