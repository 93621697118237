import React, { Component } from "react";
import ReactDOM from "react-dom";
import { firestore } from "../firebase";
import {Link} from "react-router-dom";
import Header from "components/structure/Header";
import $ from "jquery";
import Loading from "components/general/Loading";
var moment = require('moment');

export default class Transactions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingTransactions: true,
      transactions: null,
      totalPayments: 0,
      loadingPayouts: true,
      totalPayouts: 0,
      finalBalance: null,
      payouts: null
    };

  }

 

  componentDidMount() {
    window.scrollTo(0,0)

    var user = localStorage.getItem('user', null);
    if (user == null){
      return alert('Please login again');
    }


    this.setState({loadingTransactions: true})
    firestore
    .collection('transactions')
    .where('provider', '==', user)
    .onSnapshot(querySnapshot => {
      let transactions=[]
      var totalPayments = 0;
      querySnapshot.forEach(doc=>{
        totalPayments = totalPayments + doc.data().amount
        
        
        transactions.push({...{id: doc.id}, ...doc.data()})
      })
      console.log(transactions)
      this.setState({transactions: transactions, totalPayments: totalPayments})
      this.setState({loadingTransactions: false})
    })


    firestore
    .collection('payouts')
    .where('provider', '==', user)
    .onSnapshot(querySnapshot => {
      let payouts=[]
      var totalPayouts = 0;
      querySnapshot.forEach(doc=>{
        totalPayouts = totalPayouts + parseFloat(doc.data().amount)
        
        
        payouts.push({...{id: doc.id}, ...doc.data()})
      })

      this.setState({payouts: payouts, totalPayouts: totalPayouts})
      this.setState({loadingPayouts: false, finalBalance: (this.state.totalPayments * 0.9) - this.state.totalPayouts})


    })






  }

  render() {
    return (
        
        <>
        <Header selectedPage="transactions"/>

        <section className="inner_section transaction_inner_section">
          <div className="container">
            <div className="inner_page_title">
              <h2>Transactions</h2>
            </div>
            <div className="transactions_top_box">
              {this.state.totalPayments && this.state.finalBalance ? ( 
                <div className="row">
                  <div className="col-8">
                    <div className="transactions_top_item_3">
                      <h5>Total Balance After 10% Service Fees and Payouts</h5>
                      <h3><span>£{parseFloat(this.state.finalBalance).toFixed(2)}</span></h3>
                    </div>
                  </div>
                 
                  <div className="col-4">
                    <div className="transactions_top_btns noPointerEvents hideOnMobile">
                      <button style={{"max-width": "100%"}}>Weekly Payouts</button>
                    </div>
                  </div>
                </div>
              ) : (

                <div className="row">
                  <div className="col-8">
                    <div className="transactions_top_item_3">
                      <h5>No transactions found</h5>
                    </div>
                  </div>
                </div>

              )}
            </div>

            <div className="transactions_table_box">

                   {!this.state.loadingTransactions && this.state.totalPayments == 0 &&
                        <h6 style={{"text-align": "center", "margin": "0 auto 20px auto"}}>When you get a booking, the payment will appear here as a transaction. Submit quotes, get bookings.</h6>
                   }


              <table border={0} cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th>Payment</th>
                    <th>Date of Booking</th>
                    <th>Vehicle</th>
                    <th>Booking ID</th>
                  </tr>
                </thead>
                <tbody>


                    {this.state.loadingTransactions &&
                      <Loading/>
                    }


                   
                   {!this.state.loadingTransactions && this.state.transactions.map((transaction, index)=>{
                     return (
                      <tr>
                        <td><strong>£{transaction.amount}</strong></td>
                        <td>{moment(transaction.time).format('DD MMM, hh:mmA')}</td>
                        <td>{transaction.vehicle}</td>
                        <td>{transaction.booking}</td>
                      </tr>
                      );
                   })}


                 
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="inner_section transaction_inner_section" style={{"padding-top": "0px"}}>
          <div className="container">
            <div className="inner_page_title">
              <h2>Payouts</h2>
            </div>
            <div className="transactions_table_box">


                   {!this.state.loadingPayouts && this.state.totalPayouts == 0 &&
                        <h6 style={{"text-align": "center", "margin": "0 auto", "width": "100%", "margin-bottom": "20px"}}>We pay out your balance every week to your selected bank account - when you get one, it will appear here.</h6>
                   }

              <table border={0} cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>


                    {this.state.loadingPayouts &&
                      <Loading/>
                    }


                   
                   {!this.state.loadingPayouts && this.state.payouts.map((payout, index)=>{
                     return (
                      <tr>
                        <td><strong>£{parseFloat(payout.amount).toFixed(2)}</strong></td>
                        <td>{moment(payout.time).format('DD MMM, hh:mmA')}</td>
                      </tr>
                      );
                   })}



                 
                </tbody>
              </table>
            </div>
          </div>
        </section>



        </>



      
    );
  }
}

