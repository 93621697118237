import React, { Component } from "react";
import {Link, Redirect} from "react-router-dom";
import SettingsModal from "components/modals/SettingsModal";
import firebase,{firestore} from "../../firebase";

export class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      showSettingsModal: false,
      shouldRedirect: false,
      user: localStorage.getItem('user')
    };


  }


  openSettingsModal(){
    this.setState({showSettingsModal: true});
  }

  closeSettingsModal(){
    this.setState({showSettingsModal: false});
  }

  async componentDidMount(){

    window.scrollTo(0,0);



            var user = localStorage.getItem('user', null);
            if (user == null){
              
    
              localStorage.clear();
              this.setState({shouldRedirect: true});
              return alert('Please login again');
            
            }else{


                firebase.firestore()
                  .collection("providers")
                  .doc(user)
                  .get()
                  .then(userDoc => {
                        if (userDoc.exists){
                          this.setState({userDoc: userDoc.data()});
                          console.log(this.state.userDoc);
                        }
                  });

            }


    




  
  }


  render() {
    return (
      <>
      <SettingsModal
        show={this.state.showSettingsModal} 
        onClose={()=>this.closeSettingsModal()}
        onHide={()=>this.closeSettingsModal()}/>


        <header className="inner_header_area">
          <div className="inner_header_uper_area">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <div className="inner_header_logo">
                    <a href="#"><img src="/images/timewashGIF.gif" alt="logo" /></a>
                  </div>
                </div>
                <div className="col-6">
                  <div className="inner_header_user">

                    <div className="btn-group">
                      <button type="button" className="btn user_info"><span className="user_pic"><img src="/images/user-green.png" alt="img" /></span> {this.state.userDoc ? this.state.userDoc.companyName : "Loading"}</button>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="inner_header_nav">
                  <nav className="navbar navbar-expand-lg">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <div className="animate_toggle" onclick="myFunction(this)">
                        <div className="bar1" />
                        <div className="bar2" />
                        <div className="bar3" />
                      </div>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav m-auto">
                        <Link to="/requests/all">
                          <li className={this.props.selectedPage == 'requests' ? 'nav-item active' : 'nav-item'}>
                            <a className="nav-link" href="#"><span className="inner_nav_icon">
                                <i className="fas fa-car" />
                              </span> Requests</a>
                          </li>
                        </Link>
                        <Link to="/bookings/upcoming">
                          <li className={this.props.selectedPage == 'bookings' ? 'nav-item active' : 'nav-item'}>
                            <a className="nav-link" href="#"><span className="inner_nav_icon">
                                <i className="far fa-calendar-check" /></span> Bookings</a>
                          </li>
                        </Link>
                        <Link to="/transactions">
                          <li className={this.props.selectedPage == 'transactions' ? 'nav-item active' : 'nav-item'}>
                            <a className="nav-link" href="#"><span className="inner_nav_icon">
                                <i className="fas fa-wallet" />
                              </span> Transactions</a>
                          </li>
                        </Link>
                        <Link to="/profile">
                          <li className={this.props.selectedPage == 'profile' ? 'nav-item active' : 'nav-item'}>
                            <a className="nav-link" href="#"><span className="inner_nav_icon">
                                <i className="fas fa-user-circle" />
                              </span> Profile</a>
                          </li>
                        </Link>
                        <Link to="/settings">
                          <li className={this.props.selectedPage == 'settings' ? 'nav-item active' : 'nav-item'}>
                            <a className="nav-link" href="#"><span className="inner_nav_icon">
                                <i class="fas fa-user-cog"></i>
                              </span> Settings</a>
                          </li>
                        </Link>
                        

                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>

        {this.state.userDoc && !this.state.userDoc.profileCompleted && !this.state.userDoc.profileVerified &&
          <div className="container headerAlert">
            <div className="row">
              <div className="col-12">
              <div>
                <div className="alert alert-warning">
                  <strong>Welcome to TimeWash, {this.state.userDoc.fullName}!</strong> Please complete your entire profile and we will verify your account within 5 days <Link to="/profile"><strong>Update Profile</strong></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      }


        {this.state.userDoc && this.state.userDoc.profileCompleted && !this.state.userDoc.profileVerified &&
          <div className="container headerAlert">
            <div className="row">
              <div className="col-12">
              <div>
                <div className="alert alert-info">
                  <strong>Your profile is now completed, {this.state.userDoc.fullName}!</strong> Please give us up to 5 days to verify your information.
                </div>
              </div>
            </div>
          </div>
        </div>
      }


        {/*{this.state.userDoc && this.state.userDoc.profileCompleted && this.state.userDoc.profileVerified &&
          <div className="container headerAlert">
            <div className="row">
              <div className="col-12">
              <div>
                <div className="alert alert-success">
                  {this.state.userDoc.fullName}, submit quotes today to get customers. You have <strong>{this.state.userDoc && this.state.userDoc.credits != null ? this.state.userDoc.credits : 0}</strong> credits in your account. <Link to="/credits"><strong>Purchase more credits</strong></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      }*/}


      {this.state.shouldRedirect &&
        <Redirect to="/" push />
      }


      </>

    );
  }
}


export default Header;
