import React, { Component } from "react";
import ReactDOM from "react-dom";
import { firestore } from "../firebase";
import {Link, Redirect} from "react-router-dom";
import Header from "components/structure/Header";
import LoginModal from "components/modals/LoginModal";
import SignupModal from "components/modals/SignupModal";
import $ from "jquery";
import AnchorLink from 'react-anchor-link-smooth-scroll'


export default class MarketingHome extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoginModal: false,
      showSignupModal: false,
      shouldRedirect: false,
      user: localStorage.getItem('user')
    };
    this.openLoginModal = this.openLoginModal.bind(this);
    this.closeLoginModal = this.closeLoginModal.bind(this);

    this.openSignupModal = this.openSignupModal.bind(this);
    this.closeSignupModal = this.closeSignupModal.bind(this);

  }


  openLoginModal (){
    this.setState({showLoginModal: true});
  }

  closeLoginModal = () => {
    this.setState({showLoginModal: false});
  }


  openSignupModal (){
    this.setState({showSignupModal: true});
  }

  closeSignupModal = () => {
    this.setState({showSignupModal: false});
  }



  async componentDidMount(){

    window.scrollTo(0,0);
    var user = localStorage.getItem('user')


       
      setTimeout(
          function() {
            if (user != null){
              console.log(user);
              this.setState({shouldRedirect: true});
            }
          }
          .bind(this),
          500
      );


  }


  render() {
    return (


      <>
      <LoginModal          
        show={this.state.showLoginModal} 
        onClose={this.closeLoginModal}
        onHide={this.closeLoginModal}
        showSignup={this.openSignupModal}/>
      <SignupModal          
        show={this.state.showSignupModal} 
        onClose={this.closeSignupModal}
        onHide={this.closeSignupModal}
        showLogin={this.openLoginModal}/>
      <div>
        <header className="site_header" id="home">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="#"><img src="images/logo.svg" alt="logo" /></a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="animate_toggle" onclick="myFunction(this)">
                      <div className="bar1" />
                      <div className="bar2" />
                      <div className="bar3" />
                    </div>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item active">
                        <AnchorLink href='#home'><a className="nav-link hoverOpacity" href="#">Home</a></AnchorLink>
                      </li>
                      <li className="nav-item">
                          <AnchorLink href='#serviceArea'><a className="nav-link hoverOpacity" href="#">Pricing</a></AnchorLink>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link hoverOpacity" href="mailto:contact@timewash.co.uk">Contact</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link hoverOpacity" onClick={this.openSignupModal}>Signup</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link hoverOpacity" onClick={this.openLoginModal}>Login</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
        {/*Hero Area Start */}
        <section className="hero_area">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="hero_content">
                  <h1>Search, Tap, Wash</h1>
                  <p>
                    TimeWash is the app that lets you get quotes,
                    book and pay for a car wash within minutes.
                  </p>
                  <div className="hero_app">
                    <ul>
                      <li>
                        <a href="https://apps.apple.com/us/app/timewash/id1486369843?ls=1" className="hoverOpacity">
                          <img src="images/apple.png" alt="apple" />
                        </a>
                      </li>
                      <li>
                        <a href="https://play.google.com/store/apps/details?id=com.timewashco.timewashapp&hl=en_GB" className="hoverOpacity">
                          <img src="images/play-store.png" alt="play-store" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="hero_pic">
                  <img src="images/phone.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="car_wash_hero">
              <img src="images/car-hero.png" alt="img" />
            </div>
          </div>
        </section>
        {/*How it Works Area Start */}
        <section className="howItWorks_area">
          <div className="container">
            <div className="tittle_area">
              <h2>How it works</h2>
              <p>Book a wash in 3 simple steps</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="works_item">
                  <div className="works_item_pic">
                    <img src="images/illustration-1.png" alt="img" />
                  </div>
                  <div className="works_item_content">
                    <h4>Download the TimeWash app</h4>
                    <p>
                      TimeWash is available for iOS and Android -
                      once downloaded, create an account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="works_item">
                  <div className="works_item_pic">
                    <img src="images/illustration-2.png" alt="img" />
                  </div>
                  <div className="works_item_content">
                    <h4>Submit a quote request</h4>
                    <p>
                      Tell us your requirements and upload pictures of your car.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="works_item">
                  <div className="works_item_pic">
                    <img src="images/illustration-3.png" alt="img" />
                  </div>
                  <div className="works_item_content">
                    <h4>Receive quotes from service providers</h4>
                    <p>
                      Local car wash services will quote you and you select the best quote.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Area Start */}
        <section className="service_area" id="serviceArea">
          <div className="container">
            <div className="tittle_area">
              <h2>Service Providers</h2>
              <p>Looking to have a profile on TimeWash? We have one simple plan that will be perfect for you.</p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="service_plan">
                  <h3>100 free credits</h3>
                  <ul>
                    <li>Send 10 free quotes to requests</li>
                    <li>Browse all local requests</li>
                    <li>Attract customers at off-peak hours</li>
                    <li>Affordable credit options to submit quotes</li>
                  </ul>
                  <button type="button" className="hoverOpacity removeButtonStyles" onClick={this.openSignupModal}>Get Started</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="faqSection" className="faqSection">
          <h2>Service Provider FAQs</h2>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner1" data-parent="#faqSection" id="faqHeader1">
              <h3 className="panel-title">How can I sign-up?</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner1">
              <p>Filling the sign up form and sending scanned verifiable copies of proof of business identity. It takes an average of 5 days to start trading on the platform.</p>
            </div>
          </div>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner2" data-parent="#faqSection" id="faqHeader2">
              <h3 className="panel-title">Can I cancel bookings?</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner2">
              <p>Cancellation must be done up to 2 hours to expiration of quote and will mean full refund to client</p>
            </div>
          </div>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner3" data-parent="#faqSection" id="faqHeader3">
              <h3 className="panel-title">I cannot see how much paid services I have accrued on Timewash</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner3">
              <p>You can always access your purse on the portal using the 'My Money' button and you can request the funds at any time or weekly as preset.</p>
            </div>
          </div>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner4" data-parent="#faqSection" id="faqHeader4">
              <h3 className="panel-title">Can I accept cash?</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner4">
              <p>Clients who wish for additional services outside the quoted one will have to pay the difference</p>
            </div>
          </div>
        </div>
        <div id="faqSection2" className="faqMargin faqSection">
          <h2>Customer FAQs</h2>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner1customer" data-parent="#faqSection2" id="faqHeader1customer">
              <h3 className="panel-title">I have confirmed a quote and paid for a wash but I did not attend for a wash in 24hours?</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner1customer">
              <p>You can request a full refund for washes cancelled up to 1hr before the expiration of 24 hours. This will be less the admin cost for No shows! All refunds will be processed within 5 days.</p>
            </div>
          </div>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner2customer" data-parent="#faqSection2" id="faqHeader2customer">
              <h3 className="panel-title">I am not impressed by the services rendered at a car wash?</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner2customer">
              <p>Endeavour to leave a review against the service provider's profile. Likewise if you are happy, drop a review.</p>
            </div>
          </div>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner3customer" data-parent="#faqSection2" id="faqHeader3customer">
              <h3 className="panel-title">I have booked for a minivalet but now want a full valet?</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner3customer">
              <p>You can either cancel your earlier order and request new quotes. Or attend for the booked service and request more from the car wash- at a price of course!</p>
            </div>
          </div>
          <div className="faq panel">
            <div className="faqHeader" data-toggle="collapse" data-target="#faqInner4customer" data-parent="#faqSection2" id="faqHeader4customer">
              <h3 className="panel-title">Can I request a car wash for a friend's vehicle?</h3>
              <img src="images/downArrow.png" />
            </div>
            <div className="faqInner panel-collapse collapse" id="faqInner4customer">
              <p>Using the Gift icon guides you to this feature.</p>
            </div>
          </div>
        </div>
        {/*Footer Area Start */}
        <footer className="site_footer">
          <div className="footer_up_area">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer_left">
                    <div className="footer_logo">
                      <img src="images/logo.svg" alt="img" />
                    </div>
                    <div className="footer_copyright">
                      <p>
                        © Copyright TimeWash 2019
                        <br />
                        All Rights Reserved
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer_nav">
                    <ul>
                      <li>
                        <a href="privacy-policy.pdf">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="terms.pdf">Terms of Service</a>
                      </li>
                      <li>
                        <a href="mailto:contact@timewash.co.uk">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="footer_mobile_copyright">
                    <p>
                      © Copyright TimeWash 2020 All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_down_area">
            <div className="container">
              <p>CleanPay Limited. Contact: <a href={"tel:07426852555"}>07426 852 555</a></p>
              <p className="homeSocialMediaIconsContainer">
                <i className="fab fa-facebook-square hoverOpacity" />
                <i className="fab fa-twitter-square hoverOpacity" style={{marginRight: '10px', marginLeft: '10px'}} />
                <i className="fab fa-instagram hoverOpacity" />
              </p>
            </div>
          </div>
        </footer>
      </div>

      {this.state.shouldRedirect &&
        <Redirect to="/requests/all" push />
      }

      </>



      
    );
  }
}

