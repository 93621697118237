import React, { PureComponent } from "react";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import $ from "jquery";
import {Modal} from 'react-bootstrap';

import firebase,{firestore} from "../../firebase";
import DateTimePicker from 'material-ui-datetimepicker';
import DatePickerDialog from 'material-ui/DatePicker/DatePickerDialog'
import TimePickerDialog from 'material-ui/TimePicker/TimePickerDialog';
import DateFnsUtils from "@date-io/date-fns"; // import
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import New from './New'

var NumberFormat = require('react-number-format');
var moment = require('moment');


export default class LoginModal extends PureComponent {
 

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      processing: false,
      shouldRedirect: false
    };
  }


  login = async () => {

    if (this.state.email === "") return alert("Please enter your email");
    if (this.state.password === "") return alert("Please enter a password");
    

        /*this.setState({processing: false, shouldRedirect: true})
        localStorage.setItem('user', 'oc8jY5qLG1TfgXw88z3cpM3en0j1')              
        window.location.href = "requests/all"*/


    this.setState({processing: true})
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(userResponse => {          

        
        
        firebase.firestore()
          .collection("providers")
          .doc(userResponse.user.uid)
          .get()
          .then(userDoc => {
                if (userDoc.exists){
                  localStorage.setItem('user', userResponse.user.uid)     


                 //window.location.href = "/requests/all"

                  this.setState({processing: false, shouldRedirect: true})

                
                }else{
                  this.setState({processing: false})
                  return alert('Please make sure you are signing into a service provider account (and not a standard customer account).');
                }
          });



       


      })
      .catch(err => {
        this.setState({processing: false})
        alert(err.message);
      });


  }


  showSignup = () => {
    this.props.onHide();
    this.props.showSignup();
  }


  render() {

    return (
        <Modal 
        size="sm"
        show={this.props.show}
        backdrop={true} onHide={this.props.onHide} className="site_modal" id="loginModal" className="modal fade" role="dialog">

            <div className="modal-dialog modal-sm">
              {/* Modal content*/}
              <div className="modal-content">
                <div className="modal-body">
                  <p className="heading-modal">Login to your account</p>
                  <div>
                    <div className="form-group">
                      <input type="email" placeholder="Email" className="form-control"
                        onChange={e =>
                          this.setState({email: e.target.value})
                        }
                        value={this.state.email}/>
                    </div>
                    <div className="form-group">
                      <input type="password" placeholder="Password" className="form-control"
                        onChange={e =>
                          this.setState({password: e.target.value})
                        }
                        value={this.state.password}/>
                    </div>
                    <div className="form-group">
                      <button disabled={this.state.processing} className="btn btn-success col-12" onClick={this.login}>
                      
                        {!this.state.processing ? 
                          
                          <span>Login</span>

                          :

                          <img src="/images/loading.gif" style={{ width: '20px',height: '20px', position: 'relative', top: '-2px'}}/>
                        }
                      </button>
                    </div>
                  </div>
                  <p className="text-grey" style={{marginBottom: '20px'}}>Don't have an account? <a href="#" onClick={this.showSignup}>Create an account</a></p>
                  {/*<p className="text-grey">Forgot your password? <a href="#">Reset it</a></p>*/}
                </div>
              </div>
            </div>

          {this.state.shouldRedirect &&
            <Redirect to="/requests/all" push />
          }


        </Modal>
    );
  }
}

