import React, { PureComponent } from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import {Modal} from 'react-bootstrap';

import firebase,{firestore} from "../../firebase";
import DateTimePicker from 'material-ui-datetimepicker';
import DatePickerDialog from 'material-ui/DatePicker/DatePickerDialog'
import TimePickerDialog from 'material-ui/TimePicker/TimePickerDialog';
import DateFnsUtils from "@date-io/date-fns"; // import
import New from './New'

var NumberFormat = require('react-number-format');
var moment = require('moment');


export default class SettingsModal extends PureComponent {
 

  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
      companyName: '',
      showAccount: false,
      showReferrals: false,
      showSubscription: true
    };
  }

  select1 = () => {
    this.setState({
        showAccount: true,
        showReferrals: false,
        showSubscription: false
    });   
  }

  select2 = () => {
    this.setState({
        showAccount: false,
        showReferrals: true,
        showSubscription: false
    });   
  }

  select3 = () => {
    this.setState({
        showAccount: false,
        showReferrals: false,
        showSubscription: true
    });   
  }

  render() {

    return (
        <Modal 
        size="md"
        show={this.props.show}
        backdrop={true} onHide={this.props.onHide} id="accountModal" className="modal fade" role="dialog">

          <div className="modal-dialog modal-lg">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-body">
                <div className="tab">
                  <button className={this.state.showAccount ? "tablinks active" : "tablinks"} onClick={this.select1}>
                    Account
                    <i className="fa fa-angle-right" />
                  </button>
                  <button className={this.state.showReferrals ? "tablinks active" : "tablinks"} onClick={this.select2}>
                    Referrals<i className="fa fa-angle-right" /></button>
                  <button className={this.state.showSubscription ? "tablinks active" : "tablinks"} onClick={this.select3}>
                    Subscription<i className="fa fa-angle-right" /></button>
                </div>
                {this.state.showAccount &&
                  <div id="Account" className={"tabcontent active"}>
                    <form action="#">
                      <div className="form-group">
                        <input type="text" placeholder="Full Name" className="form-control" />
                      </div>
                      <div className="form-group">
                        <input type="email" placeholder="Email" className="form-control" />
                      </div>
                      <div className="form-group">
                        <input type="password" placeholder="Password" className="form-control" />
                      </div>
                      <div className="form-group">
                        <input type="password" placeholder="Confirm Password" className="form-control" />
                      </div>
                      <div className="form-group">
                        <button type="button" className="btn btn-success col-12">Save</button>
                      </div>
                    </form>
                  </div>
                }
                {this.state.showReferrals &&
                  <div id="Referrals" className={"tabcontent active"}>
                    <p>Tab Referrals</p>
                    <p>Your Subscription Status</p>
                    <h3>Active <span>(82 days left)</span></h3>
                    <div className="space" />
                    <p>Your Card</p>
                    <div className="card col-6">
                      <img src="images/mastercard.png" alt="cardImg" />
                      <p className="card-name">MasterCard</p>
                      <p className="card-number">XXXX-3985</p>
                      <p className="card-expiry">Expires on: 02/23</p>
                      <a href="#" className="card-change">Change</a>
                    </div>
                  </div>
                }
                {this.state.showSubscription &&
                  <div id="Subscription" className={"tabcontent active"}>
                    <p>Your Subscription Status</p>
                    <h3>Active <span>(82 days left)</span></h3>
                    <div className="space" />
                    <p>Your Card</p>
                    <div className="card col-6">
                      <img src="images/mastercard.png" alt="cardImg" />
                      <p className="card-name">MasterCard</p>
                      <p className="card-number">XXXX-3985</p>
                      <p className="card-expiry">Expires on: 02/23</p>
                      <a href="#" className="card-change">Change</a>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          

        </Modal>
    );
  }
}

