import React, { Component } from "react";
import ReactDOM from "react-dom";
import { firestore } from "../firebase";
import {Link} from "react-router-dom";
import Header from "components/structure/Header";
import AllRequestsItem from "components/listItems/AllRequestsItem";
import ReactModal from 'react-modal';

import Loading from "components/general/Loading";

import $ from "jquery";

var moment = require('moment');

export default class ViewAllRequests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingRequests: true,
      requests: null,
      show: true,
      requestCount: 0,
      showAll: true,
      city: "",
      currentUser: null,
      askForQuotes: null,
      askForQuotesCount: 0,
      loadingAskForQuotes: null,
      user: localStorage.getItem('user')
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  
  async componentDidMount() {

              if (this.state.user == null){
                return alert("Please login again");
              }


              this.setState({currentUser: this.state.user});

              this.searchRequests('');
              this.getAskForQuotes(this.state.user);


  }


  searchRequests = (city) => {

    this.setState({loadingRequests: true, requests: null, requestCount: 0, city: city})


    if (city == '' || city=="Requests from all cities"){
      var query = firestore
      .collection('requests')
      .orderBy("created", "desc")
    }else{
      var query = firestore
      .collection('requests').where("city", "==", city);
    }


    query.onSnapshot(querySnapshot => {
      let requests=[]
      var requestCount = 0;
      querySnapshot.forEach(doc=>{


        requests.push({...{id: doc.id}, ...doc.data()})

        requestCount++;

      })
      this.setState({requests: requests, requestCount: requestCount})
      this.setState({loadingRequests: false})
    })


  }


  getAskForQuotes = (currentUser) => {



    this.setState({loadingAskForQuotes: true});
    var query = firestore.collection('askForQuotes').where("provider", "==", currentUser);
  
    query.onSnapshot(querySnapshot => {
      let askForQuotes=[]
      var askForQuotesCount = 0;
      querySnapshot.forEach(doc=>{

        console.log(doc);



        var now = moment(new Date()); //now
        var end = moment(doc.data().created)
        var duration = moment.duration(now.diff(end));
        var hours = duration.asHours();

        if (hours < 24){
            askForQuotes.push({...{id: doc.id}, ...doc.data()})
            askForQuotesCount++;
        }    


      })
      this.setState({askForQuotes: askForQuotes, askForQuotesCount: askForQuotesCount})
      this.setState({loadingAskForQuotes: false})
    })

  }



  render() {

    let cities = [
      "Requests from all cities", 
      "Adur and Worthing Borough",
      "Adur District",
      "Allerdale Borough",
      "Amber Valley Borough",
      "Arun District",
      "Ashfield District",
      "Ashford Borough",
      "Aylesbury Vale District",
      "Babergh District",
      "Barnsley Metropolitan Borough",
      "Barrow-in-Furness Borough",
      "Basildon Borough",
      "Basingstoke and Deane Borough",
      "Bassetlaw District",
      "Bath and North East Somerset",
      "Bedford Borough",
      "Birmingham City",
      "Blaby District",
      "Blackburn with Darwen Borough",
      "Blackpool Borough",
      "Blaenau Gwent County Borough",
      "Bolsover District",
      "Bolton Metropolitan Borough",
      "Borough of Broxbourne",
      "Borough of Poole",
      "Boston Borough",
      "Bournemouth Borough",
      "Bracknell Forest",
      "Bradford Metropolitan District",
      "Braintree District",
      "Breckland District",
      "Brentwood Borough",
      "Bridgend County Borough",
      "Brighton and Hove City",
      "Bristol City",
      "Broadland District",
      "Bromsgrove District",
      "Broxtowe Borough",
      "Buckinghamshire County",
      "Burnley Borough",
      "Bury Metropolitan Borough",
      "Caerphilly County Borough",
      "Calderdale Metropolitan Borough",
      "Cambridge City",
      "Cambridgeshire County",
      "Cannock Chase District",
      "Canterbury City",
      "Cardiff",
      "Carlisle City",
      "Carmarthenshire County",
      "Castle Point Borough",
      "Central Bedfordshire",
      "Ceredigion County",
      "Charnwood Borough",
      "Chelmsford City",
      "Cheltenham Borough",
      "Cherwell District",
      "Cheshire East (Unitary)",
      "Cheshire West and Chester",
      "Chesterfield Borough",
      "Chichester District",
      "Chiltern District",
      "Chorley",
      "Christchurch Borough",
      "City of Lincoln",
      "City of London",
      "City of York",
      "Colchester Borough",
      "Conwy County Borough",
      "Copeland Borough",
      "Corby Borough",
      "Cornwall (Unitary)",
      "Cotswold District",
      "Coventry City",
      "Craven District",
      "Crawley Borough",
      "Cumbria County",
      "Dacorum",
      "Darlington Borough",
      "Dartford Borough",
      "Daventry District",
      "Denbighshire County",
      "Derby City",
      "Derbyshire County",
      "Derbyshire Dales District",
      "Devon County",
      "Doncaster Metropolitan Borough",
      "Dorset County",
      "Dover District",
      "Dudley Metropolitan Borough",
      "Durham County",
      "East Cambridgeshire District",
      "East Devon District",
      "East Dorset District",
      "East Hampshire District",
      "East Hertfordshire District",
      "East Lindsey District",
      "East Northamptonshire",
      "East Riding of Yorkshire",
      "East Staffordshire Borough",
      "East Suffolk",
      "East Sussex County",
      "Eastbourne Borough",
      "Eastleigh Borough",
      "Eden District",
      "Elmbridge Borough",
      "Epping Forest District",
      "Epsom and Ewell Borough",
      "Erewash Borough",
      "Essex County",
      "Exeter City",
      "Fareham Borough",
      "Fenland District",
      "Flintshire County",
      "Forest Heath District",
      "Forest of Dean District",
      "Fylde Borough",
      "Gateshead Metropolitan Borough",
      "Gedling Borough",
      "Gloucester City",
      "Gloucestershire County",
      "Gosport Borough",
      "Gravesham Borough",
      "Great Yarmouth Borough",
      "Guildford Borough",
      "Gwynedd County",
      "Halton Borough",
      "Hambleton District",
      "Hampshire County",
      "Harborough District",
      "Harlow",
      "Harrogate Borough",
      "Hart District",
      "Hartlepool Borough",
      "Hastings Borough",
      "Havant Borough",
      "Herefordshire",
      "Hertfordshire County",
      "Hertsmere Borough",
      "High Peak Borough",
      "Hinckley and Bosworth Borough",
      "Horsham District",
      "Huntingdonshire District",
      "Hyndburn Borough",
      "Ipswich Borough",
      "Isle of Anglesey County",
      "Isle of Wight",
      "Isles of Scilly",
      "Kent County",
      "Kettering Borough",
      "King's Lynn and West Norfolk Borough",
      "Kingston-upon-Hull City",
      "Kirklees",
      "Knowsley Metropolitan Borough",
      "Lancashire County",
      "Lancaster City",
      "Leeds City",
      "Leicester City",
      "Leicestershire County",
      "Lewes District",
      "Lichfield District",
      "Lincolnshire County",
      "Liverpool City",
      "London Borough of Barking and Dagenham",
      "London Borough of Barnet",
      "London Borough of Bexley",
      "London Borough of Brent",
      "London Borough of Bromley",
      "London Borough of Camden",
      "London Borough of Croydon",
      "London Borough of Ealing",
      "London Borough of Enfield",
      "London Borough of Hackney",
      "London Borough of Hammersmith & Fulham",
      "London Borough of Haringey",
      "London Borough of Harrow",
      "London Borough of Havering",
      "London Borough of Hillingdon",
      "London Borough of Hounslow",
      "London Borough of Islington",
      "London Borough of Lambeth",
      "London Borough of Lewisham",
      "London Borough of Merton",
      "London Borough of Newham",
      "London Borough of Redbridge",
      "London Borough of Richmond upon Thames",
      "London Borough of Southwark",
      "London Borough of Sutton",
      "London Borough of Tower Hamlets",
      "London Borough of Waltham Forest",
      "London Borough of Wandsworth",
      "Luton Borough",
      "Maidstone Borough",
      "Maldon District",
      "Malvern Hills District",
      "Manchester City",
      "Mansfield District",
      "Medway",
      "Melton Borough",
      "Mendip District",
      "Merthyr Tydfil County Borough",
      "Mid Devon District",
      "Mid Suffolk District",
      "Mid Sussex District",
      "Middlesbrough Borough",
      "Milton Keynes",
      "Mole Valley District",
      "Monmouthshire County",
      "Neath Port Talbot County Borough",
      "New Forest District",
      "Newark and Sherwood District",
      "Newcastle-Under-Lyme District",
      "Newport City",
      "Newcastle-upon-Tyne City",
      "Norfolk County",
      "North Devon",
      "North Dorset District",
      "North East Derbyshire District",
      "North East Lincolnshire",
      "North Hertfordshire District",
      "North Kesteven District",
      "North Lincolnshire",
      "North Norfolk District",
      "North Somerset",
      "North Tyneside Metropolitan Borough",
      "North Warwickshire Borough",
      "North West Leicestershire District",
      "North Yorkshire County",
      "Northampton Borough",
      "Northamptonshire County",
      "Northumberland",
      "Norwich City",
      "Nottingham City",
      "Nottinghamshire County",
      "Nuneaton and Bedworth Borough",
      "Oadby and Wigston District",
      "Oldham Metropolitan Borough",
      "Oxford City",
      "Oxfordshire County",
      "Pembrokeshire County",
      "Pendle Borough",
      "Perth and Kinross",
      "Peterborough City",
      "Plymouth City",
      "Portsmouth City",
      "Powys County",
      "Preston City",
      "Purbeck District",
      "Reading Borough",
      "Redcar and Cleveland",
      "Redditch Borough",
      "Reigate & Banstead Borough",
      "Rhondda Cynon Taf County Borough",
      "Ribble Valley Borough",
      "Richmondshire District",
      "Rochdale Metropolitan Borough",
      "Rochford District",
      "Rossendale Borough",
      "Rother District",
      "Rotherham Metropolitan Borough",
      "Royal Borough of Greenwich",
      "Royal Borough of Kensington and Chelsea",
      "Royal Borough of Kingston upon Thames",
      "Royal Borough of Windsor and Maidenhead",
      "Rugby Borough",
      "Runnymede Borough",
      "Rushcliffe Borough",
      "Rushmoor Borough",
      "Rutland County",
      "Ryedale District",
      "Salford City",
      "Sandwell Metropolitan Borough",
      "Scarborough Borough",
      "Sedgemoor District",
      "Sefton Metropolitan Borough",
      "Selby District",
      "Sevenoaks District",
      "Sheffield City",
      "Shepway District",
      "Shropshire - Unitary",
      "Slough Borough",
      "Solihull Metropolitan Borough",
      "Somerset County",
      "South Buckinghamshire District",
      "South Cambridgeshire District",
      "South Derbyshire District",
      "South Gloucestershire",
      "South Hams District",
      "South Holland District",
      "South Kesteven District",
      "South Lakeland District",
      "South Norfolk District",
      "South Northamptonshire",
      "South Oxfordshire District",
      "South Ribble Borough",
      "South Somerset District",
      "South Staffordshire",
      "South Tyneside",
      "Southampton City",
      "Southend-on-Sea Borough",
      "Spelthorne Borough",
      "St Albans City and District",
      "St Edmundsbury Borough",
      "St Helens Metropolitan Borough",
      "Stafford Borough",
      "Staffordshire County",
      "Staffordshire Moorlands District",
      "Stevenage Borough",
      "Stockport Metropolitan Borough",
      "Stockton-on-Tees Borough",
      "Stoke-on-Trent City",
      "Strabane District",
      "Stratford-on-Avon District",
      "Stroud District",
      "Suffolk County",
      "Sunderland City",
      "Surrey County",
      "Surrey Heath Borough",
      "Swale Borough",
      "Swansea City and Borough",
      "Swindon Borough",
      "Tameside Metropolitan Borough",
      "Tamworth Borough",
      "Tandridge District",
      "Taunton Deane Borough",
      "Teignbridge District",
      "Telford & Wrekin",
      "Tendring District",
      "Test Valley Borough",
      "Tewkesbury Borough",
      "Thanet District",
      "Three Rivers District",
      "Thurrock",
      "Tonbridge and Malling Borough",
      "Torbay",
      "Torfaen County Borough",
      "Torridge District",
      "Trafford Metropolitan Borough",
      "Tunbridge Wells Borough",
      "Uttlesford District",
      "Vale of Glamorgan",
      "Vale of White Horse District",
      "Wakefield Metropolitan District",
      "Walsall Metropolitan Borough",
      "Warrington Borough",
      "Warwick District",
      "Warwickshire County",
      "Watford Borough",
      "Waverley Borough",
      "Wealden District",
      "Wellingborough Borough",
      "Welwyn Hatfield",
      "West Berkshire",
      "West Devon Borough",
      "West Dorset District",
      "West Lancashire Borough",
      "West Lindsey District",
      "West Oxfordshire District",
      "West Somerset District",
      "West Sussex County",
      "Westminster City",
      "Weymouth and Portland Borough",
      "Wigan Metropolitan Borough",
      "Wiltshire",
      "Winchester City",
      "Wirral",
      "Woking Borough",
      "Wokingham Borough",
      "Wolverhampton City",
      "Worcester City",
      "Worcestershire County",
      "Worthing Borough",
      "Wrexham County Borough",
      "Wychavon District",
      "Wycombe District",
      "Wyre",
      "Wyre Forest District",

    ];


    return (
        
        <>



        <Header selectedPage="requests"/>


        <section className="requests_area">

 
            
          <div className="container">

            <div className="inner_page_title">
              <h2>Requests</h2>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="inner_tabs">
                  {/* Nav tabs */}
                  <ul className="nav nav-tabs">
                    <Link to={'/requests/all'} onClick={()=>this.setState({showAll: true})}>
                      <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#">All Requests</a>
                      </li>
                    </Link>
                      <li className="nav-item" onClick={()=>this.setState({showAll: false})}>
                        <a className="nav-link" data-toggle="tab" href="#">My responses</a>
                      </li>

                  </ul>
                  {/* Tab panes */}
                  <div className="tab-content">
                    <div className="tab-pane active container" id="all-requests">

                      <div style={{'margin-top': '20px'}}>


                          {this.state.loadingAskForQuotes &&
                            <Loading/>
                          }

                         
                         {!this.state.loadingAskForQuotes && this.state.askForQuotesCount != 0 && this.state.askForQuotes.map((AFQ, index)=>{
                           return (
                              <div className="alert alert-info">
                                Someone has requested your company to quote on this request! <Link to={"/request/"+AFQ.request}>Quote here</Link> before it expires or gets booked.
                              </div>
                            );
                         })}
                     

                      </div>



                      <div className="col-md-12" style={{'padding': '0'}}>
                        <select className="form-group form-control profile_form_inputs" style={{'background': 'white', 'margin': '22px auto 14px auto'}} 
                            onChange={event => this.searchRequests(event.target.value)} 
                            value={this.state.city}>
                         <option value="">Select city</option>
                          {cities.map((city, index)=>{
                           return (
                            <option value={city}>{city}</option>
                            );
                          })}
                        </select>
                      </div>



                      {this.state.loadingRequests &&
                        <Loading/>
                      }

                      {!this.state.loadingRequests && this.state.requestCount == 0 &&
                        <h5 style={{'margin-top': '20px'}}>No requests found - please check again later</h5>
                      }

                     
                     {!this.state.loadingRequests && this.state.requests.map((request, index)=>{
                       return (
                         <AllRequestsItem
                          key={index}
                          request={request}
                          show={this.state.showAll}
                          />
                        );
                     })}
                     
  

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        </>



      
    );
  }
}

